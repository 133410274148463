@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";

.audience-buttons {
    margin-bottom: $baseline * 5;
    display: flex;
    * {
        margin-right: $baseline * 2;
    }
    div {
        display: flex;
        align-items: center;
        color: $blue;
        font-size: $font-size-default;
        text-decoration: underline;
    }
}

.thomas-table__url {
    color: $blue;
    font-size: $font-size-small;
    font-weight: 500;
    min-width: 0;
}

.thomas-table__companyIndustry,
.thomas-table__companyAnnualSales,
.thomas-table__city {
    text-align: center;
}

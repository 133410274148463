.analytics-table {
  line-height: 2.5; }
  .analytics-table > *:first-child {
    justify-content: start; }

.analytics-referrers {
  line-height: 1.5;
  grid-template-columns: 20% 10% 14% 11% 13% 10% 12% 10%; }

.analytics-sessionduration-single {
  grid-template-columns: 22% 23% 14% 11% 10% 8% 12%; }

.analytics-referrer {
  grid-template-columns: 40% 19% 12% 12% 9% 8%; }

.analytics-exitpages {
  -ms-grid-columns: 85% 15%;
  grid-template-columns: 85% 15%; }
  .analytics-exitpages div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .analytics-exitpages div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }

.analytics-toppages,
.analytics-searchterms,
.analytics-browsers {
  -ms-grid-columns: 70% 20% 10%;
  grid-template-columns: 70% 20% 10%; }
  .analytics-toppages div:nth-child(1),
  .analytics-searchterms div:nth-child(1),
  .analytics-browsers div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .analytics-toppages div:nth-child(2),
  .analytics-searchterms div:nth-child(2),
  .analytics-browsers div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .analytics-toppages div:nth-child(3),
  .analytics-searchterms div:nth-child(3),
  .analytics-browsers div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }

.analytics-entrypages {
  -ms-grid-columns: 62% 12% 12% 14%;
  grid-template-columns: 62% 12% 12% 14%; }
  .analytics-entrypages div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .analytics-entrypages div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .analytics-entrypages div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }
  .analytics-entrypages div:nth-child(4) {
    -ms-grid-column: 4;
    -ms-grid-row: 1; }

.accounts-selection {
  -ms-grid-columns: 30% 30% 40%;
  grid-template-columns: 30% 30% 40%; }
  .accounts-selection div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .accounts-selection div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .accounts-selection div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }

.thomas-table__pageUrl,
.thomas-table__visitorName,
.thomas-table__pagesCount,
.thomas-table__length,
.thomas-table__dateTime,
.thomas-table__openDetails {
  color: #28628F;
  font-weight: 500; }

.thomas-table__durationRange,
.thomas-table__depth {
  text-decoration: underline; }

.thomas-table__funnelAnalysis:hover,
.thomas-table__dateTime:hover,
.thomas-table__openDetails:hover {
  background-color: #dfe6ec;
  cursor: pointer; }

.analytics-control {
  margin-top: 1rem; }

.analytics-home-cards {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, auto);
  grid-gap: 1.5rem;
  grid-row-gap: 1rem; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .analytics-home-cards {
      display: -ms-grid;
      -ms-grid-columns: 1fr 1fr;
      -ms-grid-rows: auto auto auto auto; }
      .analytics-home-cards .card:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(2) {
        -ms-grid-column: 2;
        -ms-grid-row: 1;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(4) {
        -ms-grid-column: 2;
        -ms-grid-row: 2;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(6) {
        -ms-grid-column: 2;
        -ms-grid-row: 3;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(8) {
        -ms-grid-column: 2;
        -ms-grid-row: 4;
        margin: 0.5rem; } }
  @media (max-width: 1047.98px) {
    .analytics-home-cards {
      grid-template-columns: 1fr; } }

.analytics-sessions-cards {
  margin-top: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  grid-row-gap: 1rem; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .analytics-sessions-cards {
      display: flex;
      flex-wrap: wrap; }
      .analytics-sessions-cards .session-depth:first-child {
        margin-right: 2rem; } }
  @media (max-width: 1047.98px) {
    .analytics-sessions-cards {
      grid-template-columns: 1fr; } }

@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";
@import "global/bootstrap/mixins";
@import "global/global-mixins/global-mixins";

.top-countcard {
  padding: $baseline * 2;
  width: 100%;
  font-size: $font-size-default;
  font-weight: 100;
}
.top-countcard--list {
  padding-left: 0;
}
.top-countcard--row {
  display: flex;
  position: relative;
}
.top-countcard--order {
  color: $gray-dark;
  padding-right: $baseline;
  width: 38px;
}

.top-countcard--order-color {
  width: 1rem;
  height: 1rem;
  margin: 0.25rem;
  margin-top: 0.5rem;
}

.top-countcard--ref {
  color: darken($cyan, 15%);
  padding-right: $baseline;
  position: absolute;
  background: white;
  z-index: 2;
}
.top-countcard--fill {
  color: $gray-medium;
  position: absolute;
  top: 15px;
  z-index: 1;
  border-top: 1px solid #dfe6ec;
  width: calc(100% - 56px);
}
.top-countcard--total {
  font-weight: bold;
  padding-left: $baseline;
  position: absolute;
  right: 0.25rem;
  background: white;
  z-index: 2;
}

.top-countcard--view-all {
  color: $blue;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  :first-child {
    margin-left: $baseline;
  }
}

.topcountcard-piechart {
  float: left;

  svg {
    margin: 0 !important;
    margin-right: 2rem;
  }
}

.search-term-show-more {
  color: $blue;
  font-size: $font-size-small;
  padding-top: $baseline;
  font-weight: 500;
  cursor: pointer;
}

.topcountcard-loading {
  .piechart-loading-donut {
    margin: 0;
    margin-right: 1rem;
    float: left;
  }
  .piechart-legend {
    height: 140px;
  }
}

.loading-2line-block {
  position: absolute;
  width: 100%;
}

.top-countcard--column {
  display: flex;
  flex-direction: column;
  position: relative;
}

.topcountcard-pie-desc {
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
  }
}

.advanced-settings {
  margin: 1rem;
  border-bottom: 1px dotted #A4ACB3; }

.advanced-settings--row {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 45% 55%;
  position: relative; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .advanced-settings--row {
      display: block;
      width: 50%; } }

.advanced-settings--textbox {
  position: relative; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .advanced-settings--textbox {
      display: block;
      width: 50%; } }

.advanced-settings--add {
  font-size: 2.5rem;
  color: #6cb33f;
  position: absolute;
  top: 0.75rem;
  left: calc(45% - 1.75rem);
  cursor: pointer; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .advanced-settings--add {
      left: calc(45% + 2.5rem); } }

.advanced-settings--active-items {
  margin: 1.5rem 0 0 1.5rem;
  display: flex;
  flex-wrap: wrap; }
  .advanced-settings--active-items > * {
    margin: 0 0.5rem 0.5rem 0.5rem;
    display: flex;
    align-items: center;
    padding-left: 0.5rem; }

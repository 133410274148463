.data-integration {
  margin: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dotted #A4ACB3; }

.data-integration__subheader {
  margin-bottom: 0.5rem; }

.data-integration__subheader-info {
  font-size: 1.0625rem;
  color: #616668;
  font-style: italic;
  font-weight: 100;
  margin-bottom: 1rem; }

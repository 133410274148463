@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";
@import "global/bootstrap/mixins";
@import "global/global-mixins/global-mixins";

body {
  background-color: unset !important;
}

.home-cards {
  display: -ms-grid;
  $elements: 4;
  -ms-grid-columns: 1fr 1rem 1fr 1rem 1fr !important;

  .top-countcard {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }
  .session-depth {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
  }
  .session-length {
    -ms-grid-column: 5;
    -ms-grid-row: 1;
  }

  grid-template-columns: 325px 1fr 1fr !important;
  grid-gap: 2%;
  grid-row-gap: $baseline * 2 !important;
  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr 1fr 1fr !important;
  }
}

.hvActions {
  $elements: 7;
  -ms-grid-columns: 230px 145px 60px 3fr 3fr 4fr;
  -ms-grid-rows: 50px;
  @for $i from 1 to $elements {
    div:nth-child(#{$i}) {
      -ms-grid-column: $i;
      -ms-grid-row: 1;
    }
  }
  grid-template-columns: 230px 145px 60px 3fr 3fr 4fr;
  @include mq(mediumv) {
    grid-template-columns: 200px 125px 50px 3fr 3fr 4fr;
  }
  @include media-breakpoint-down(md) {
    grid-template-columns: 200px 125px 50px 3fr 3fr 4fr;
  }
  > * {
    justify-content: start;
  }
  .thomas-table__timestamp {
    color: $blue;
    &:hover {
      background-color: $gray;
      cursor: pointer;
    }
  }
  .thomas-table__location {
    div {
      padding-left: $baseline;
    }
  }
}
.row-open {
  -ms-grid-rows: 50px 1fr;
}

.hvAction-icon {
  margin-right: $baseline;
}

.thomas-table__actionType {
  justify-content: center;
}

.wt-logo {
  width: 23em !important;
  @include media-breakpoint-down(md) {
    width: 14em !important;
  }
}

.nav-active {
  .wt-logo {
    width: 15em !important;
  }
}

.section-header--with-export {
  display: flex;
  .results-control {
    flex: 1;
  }
}

@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";

.active-item {
  // height: 2.35rem;
  padding: 0;
  color: $blue;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-left: $baseline;

  svg {
    margin: 0 0.5rem;
  }
}

.table-row-details {
  grid-area: 2/1/2/8;
  background-color: #fff;
  border-top: 1px dashed #dfe6ec;
  border-bottom: 1px dashed #dfe6ec;
  align-items: start !important;
  flex-direction: column;
  padding-left: 1rem !important;
  -ms-grid-column: 1 !important;
  -ms-grid-row: 2 !important;
  -ms-grid-column-span: 7 !important; }
  .table-row-details:hover {
    background-color: #fff;
    cursor: default; }

.table-row-details--header {
  padding: 0.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  width: 100%; }

.table-row-details--close {
  float: right;
  color: #28628F;
  font-weight: 800;
  padding: 0 1rem;
  cursor: pointer; }

.table-row-details--content {
  width: 100%;
  font-size: 1.25rem; }

.table-details--rows {
  padding: 0.5rem;
  padding-right: 1.5rem; }

.table-details--row {
  padding: 0.25rem;
  display: flex;
  font-size: 1.0625rem; }
  .table-details--row:nth-child(odd) {
    background-color: #ecf1f5; }
  .table-details--row:nth-child(even) {
    background-color: #fff; }
  .table-details--row b {
    width: 150px; }

.welcome-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: calc(50% - 24rem);
  left: calc(50% - 37.5rem);
  width: 75rem;
  height: 48rem;
  z-index: 10002;
  background-color: white;
  box-shadow: 0px 0px 10px 0px #616668;
  padding: 2rem; }
  @media (max-width: 1047.98px) {
    .welcome-screen {
      width: 40rem;
      left: calc(50% - 20rem);
      top: 2rem;
      height: 90%;
      overflow-y: scroll;
      text-align: center;
      padding: 2rem 1rem; } }
  @media (max-width: 767.98px) {
    .welcome-screen {
      width: 20rem;
      left: calc(50% - 10rem); } }

.welcome-screen-overlay {
  position: fixed;
  display: none;
  z-index: 10001;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  text-decoration: none; }
  .welcome-screen-overlay.active {
    display: block; }

.welcome-screen__header {
  flex: 2; }
  .welcome-screen__header h1 {
    border-bottom: #dfe6ec 1px solid;
    padding-bottom: 1rem; }

.welcome-screen__body {
  flex: 10; }

.welcome-screen__footer {
  flex: 1;
  padding-top: 1rem;
  display: flex;
  justify-content: center; }
  @media (max-width: 1047.98px) {
    .welcome-screen__footer {
      position: relative;
      padding-top: 2rem;
      margin: 2rem; } }

.welcome-screen-terms-data {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1047.98px) {
    .welcome-screen-terms-data {
      flex-direction: column;
      align-items: center; } }

.data-block-1 {
  text-align: center;
  width: 20rem; }
  .data-block-1 img {
    width: 20rem; }

.data-block-2 {
  text-align: center; }
  .data-block-2 img {
    width: 23.5rem; }

.data-block-3 {
  text-align: center; }
  .data-block-3 img {
    width: 19rem; }

.db-block {
  display: flex;
  flex-direction: column; }
  @media (max-width: 767.98px) {
    .db-block {
      width: 100%;
      padding: 2rem 0; }
      .db-block img {
        width: 100%; } }

.welcome-screen-terms-data {
  margin: 2rem 0; }

.welcome-screen__steps {
  display: flex;
  align-items: flex-end; }
  @media (max-width: 1047.98px) {
    .welcome-screen__steps {
      position: absolute;
      bottom: -2.5rem; } }

.ws-step {
  margin: 0 3px;
  border: 3px white solid;
  border-radius: 25px;
  background: #dfe6ec; }
  .ws-step.active {
    border: 3px solid #c8d6e2; }

.ws-step-ball {
  width: 20px;
  height: 20px;
  border: 3px white solid;
  border-radius: 20px; }

.data-block_header {
  font-size: 1.25rem; }

.terms-and-conditions {
  border: #ecf1f5 1px solid;
  height: 5rem;
  margin: 0;
  overflow-y: scroll; }
  .terms-and-conditions p {
    font-size: 0.75em; }

.tac-header {
  font-size: 1.1em;
  font-weight: 500; }

.tac-accepter {
  position: relative;
  padding-top: 0.25em; }
  .tac-accepter input {
    margin-right: 0.5rem; }
  .tac-accepter span {
    font-size: 1.1em;
    font-weight: 500; }

.tac-accept-notifier {
  position: absolute;
  left: 0;
  bottom: -1.25rem;
  color: #BC4239; }

.next-step-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 2rem 2rem 0; }
  @media (max-width: 1047.98px) {
    .next-step-btn {
      margin: 0;
      right: unset; } }

.skip-step-link {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 2rem 0.5rem 0;
  color: #28628F;
  cursor: pointer; }
  @media (max-width: 767.98px) {
    .skip-step-link {
      bottom: -4.5rem;
      right: unset; } }

.copy-tagging-code {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 2rem;
  color: #28628F;
  cursor: pointer; }

.clickable {
  cursor: pointer; }

.welcome-screen--target-audience {
  height: 27rem;
  overflow-y: scroll;
  background: #ecf1f5; }
  .welcome-screen--target-audience .visitor-demo-settings {
    background: white;
    padding-left: 1rem;
    border: none; }

.welcome-screen--high-value-pages {
  background: #ecf1f5;
  padding: 0.1em 0;
  max-height: 27rem;
  overflow-y: scroll; }
  .welcome-screen--high-value-pages .high-value-page-settings {
    background: white;
    padding-left: 1rem;
    border: none; }

.welcome-screen-technical-settings {
  background: #ecf1f5;
  padding: 0.1em 0;
  max-height: 27rem;
  overflow-y: scroll; }
  .welcome-screen-technical-settings .basic-settings {
    background: white;
    padding: 1rem;
    border: none; }

.font-size-40 {
  font-size: 40px; }

.noscroll {
  overflow: hidden; }

.toggleswitch-box {
  border: 1px #dfe6ec solid;
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  margin-bottom: 0.5rem;
  background-color: #fff; }

.toggleswitch-title {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  color: #212529; }

.thomas-toggleswitch {
  z-index: 10;
  position: relative;
  width: 150px;
  height: 40px;
  margin: 0.5rem; }

.thomas-toggleswitch-white-l {
  position: absolute;
  left: -5px;
  z-index: 10;
  width: 35px;
  height: 40px;
  background-color: white; }

.thomas-toggleswitch-white-r {
  position: absolute;
  left: 110px;
  z-index: 10;
  width: 35px;
  height: 40px;
  background-color: white; }

.toggleswitch-background {
  z-index: 2;
  position: absolute;
  left: 30px;
  width: 80px;
  border-radius: 34px;
  height: 40px;
  background-color: #dfe6ec;
  border: 1px solid #dfe6ec;
  -webkit-transition: 0.25s;
  transition: 0.25s; }
  .toggleswitch-background.active {
    border: 1px solid #6CB33F; }

.toggleswitch-button {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  height: 40px;
  width: 40px;
  left: 30px;
  background-color: #6CB33F;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: 0.25s;
  transition: 0.25s; }
  .toggleswitch-button.active {
    transform: translateX(40px); }

.toggleswitch-yes {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  color: #212529;
  left: -1px;
  top: 8px;
  font-weight: bold;
  -webkit-transition: 0.25s;
  transition: 0.25s; }
  .toggleswitch-yes.active {
    transform: translateX(40px); }

.toggleswitch-no {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  color: #212529;
  left: 78px;
  top: 8px;
  font-weight: bold;
  -webkit-transition: 0.25s;
  transition: 0.25s; }
  .toggleswitch-no.active {
    transform: translateX(40px); }

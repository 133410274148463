@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";
@import "global/bootstrap/mixins";
@import "global/global-mixins/global-mixins";

.session-depth {
  padding: $baseline * 2;
  width: 100%;
}

.session-graph {
  width: 100%;
  height: 200px;
}

.session-graph--wrapper {
  width: 100%;
  height: 100%;

  svg {
    text {
      font-size: 11px !important;
      fill: $gray-medium !important;
    }
  }
}

.impressions {
  border: none !important;
  padding: 0 !important;
}

@include media-breakpoint-up(lg) {
  .custom-session-graph--wrapper {
    height: 400px;
    width: auto;
  }
}

@include media-breakpoint-down(xs) {
  .custom-session-graph--wrapper {
    height: 300px;
    width: auto;
  }
  h4.chart-title {
    margin: 0 1.5rem;
  }
  h5.chart-legends {
    font-size: $font-size-sm;
    margin: 0 1.5rem;
  }
}

.title-and-legends-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.chart-legends {
  display: flex;
  flex-wrap: wrap;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  margin: 1rem;
  color: #616668;
}

h5.chart-legends > div {
  margin-right: 1.5rem;
}

.chart-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1.5rem;
}

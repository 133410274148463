.top-links {
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: 100; }

.top-links--row {
  display: flex;
  position: relative;
  margin-bottom: 0 !important; }
  .top-links--row:nth-child(odd) {
    background-color: #fff; }
  .top-links--row:nth-child(even) {
    background-color: #ecf1f5; }

.top-links--order {
  color: #616668;
  margin-left: 0.5rem;
  min-width: 32px; }

.top-links--url {
  color: #188dbf;
  font-size: 1.0625rem;
  padding-right: 4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.top-links--total {
  font-weight: bold;
  padding-left: 0.5rem;
  position: absolute;
  right: 0.5rem; }

.table td {
  vertical-align: middle; }

.results-table__visitorName {
  color: #28628F;
  font-size: 1.25rem; }
  .results-table__visitorName small {
    color: #616668;
    font-size: 0.875rem;
    font-weight: 200;
    margin-left: 0.5rem; }

.results-table__visitDate,
.results-table__visitTime {
  text-align: center; }

.results-table__table {
  border: 1px solid #A4ACB3; }

.results-table__table th {
  background-color: #dfe6ec;
  color: black;
  border: 1px solid #A4ACB3;
  font-weight: bolder !important; }

.results-table__table tr:nth-child(odd) {
  background-color: #fff; }

.results-table__table tr:nth-child(even) {
  background-color: #ecf1f5; }

.results-table__sortable {
  text-decoration: underline;
  color: #28628F;
  cursor: pointer; }

.results-table__table td {
  border-right: 1px solid #A4ACB3;
  border-left: 1px solid #A4ACB3; }

@media (max-width: 1047.98px) {
  .results-table__table td {
    padding: 0.5rem; }
  .results-table__pagesInSession,
  .results-table__sessionDuration,
  .results-table__visitTime {
    width: 10%; }
  .results-table__visitDate {
    width: 15%; } }

.thomas-row-clickable:hover {
  background-color: #dfe6ec !important;
  cursor: pointer; }

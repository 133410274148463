.thomas-pagination {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #A4ACB3;
  display: flex;
  color: #28628F; }
  @media (max-width: 1047.98px) {
    .thomas-pagination {
      flex-wrap: wrap; } }

.pagination-item {
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.pagination-item__num {
  width: 32px;
  border-right: 1px solid #A4ACB3; }
  .pagination-item__num.active {
    background-color: #28628F;
    color: #fff; }

.pagination-item__prev {
  padding: 0 0.5rem;
  border-right: 1px solid #A4ACB3; }
  .pagination-item__prev.active {
    color: #A4ACB3;
    cursor: default; }

.pagination-item__next {
  padding: 0 0.5rem; }
  .pagination-item__next.active {
    color: #A4ACB3;
    cursor: default; }

.pagination-index__list {
  flex: 3; }
  @media (max-width: 1047.98px) {
    .pagination-index__list {
      display: none; } }

.pagination-index__dropdown {
  display: none; }
  @media (max-width: 1047.98px) {
    .pagination-index__dropdown {
      display: flex;
      flex: 1; } }

.pagination-index__dropdown-custom {
  font-size: medium;
  flex: 1; }

.pagination-index__key {
  border-right: 1px solid #A4ACB3;
  flex: 1;
  white-space: nowrap;
  padding: 2px; }
  .pagination-index__key:last-child {
    border-right: 0px; }
  .pagination-index__key.active {
    background-color: #28628F;
    color: #fff; }

.pagination-index__search-bar {
  flex: 1; }
  @media (max-width: 1047.98px) {
    .pagination-index__search-bar {
      flex: 1; } }

//Brought over from grid-settings.scss

@mixin mq($mq) {
  @if $mq == smallv  { @media (max-height: $small-screen-vertical) and (min-width: $large-screen) { @content; } }
  @if $mq == mediumv  { @media (max-height: $medium-screen-vertical) and (min-width: $large-screen) { @content; } }
  @if $mq == small  { @media (max-width: $medium-screen) { @content; } }
  @if $mq == medium  { @media (min-width: $medium-screen) { @content; } }
  @if $mq == large { @media (min-width: $large-screen) { @content; } }
  @if $mq == xlarge { @media (min-width: $xlarge-screen) { @content; } }
  @if $mq == retina  { @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { @content; } }
}
.rfi-submission {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: #fff;
  margin-bottom: 0;
  padding-bottom: 0; }

.rfi-submission--title {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 900;
  margin-bottom: 1rem; }

.rfi-submission--logo {
  width: 32px;
  margin-right: 0.5rem; }

.rfi-submission--rows {
  border: 1px solid #A4ACB3;
  margin-bottom: 1rem; }

.rfi-submission--row {
  padding: 0.25rem;
  display: grid;
  grid-template-columns: 175px auto; }
  .rfi-submission--row:nth-child(odd) {
    background-color: #fff; }
  .rfi-submission--row:nth-child(even) {
    background-color: #ecf1f5; }

.rfi-details--header {
  float: left; }

.hvActions-rfi-title {
  padding-left: 2.25rem; }

.hvActions-rfi {
  margin: 0.5rem;
  padding: 0; }

.hvActions-rfi-rows {
  border: none;
  margin-right: 1rem; }

.hvActions-rfi-row {
  padding-left: 1.5rem;
  font-size: 1.0625rem; }
  .hvActions-rfi-row:nth-child(even) {
    background-color: #fff; }
  .hvActions-rfi-row:nth-child(odd) {
    background-color: #ecf1f5; }
  .hvActions-rfi-row b {
    font-weight: 300; }
  .hvActions-rfi-row div {
    font-weight: 500; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .hvActions-rfi-row {
      display: flex; }
      .hvActions-rfi-row b {
        flex: 1; }
      .hvActions-rfi-row div {
        flex: 5; } }

.hvActions-filters {
  display: flex;
  padding: 0.75rem;
  padding-top: 0.5rem; }
  .hvActions-filters input {
    margin-right: 0.5rem; }
  .hvActions-filters .hvActions-filters--phone {
    margin-right: 2rem; }
  .hvActions-filters p {
    margin: 0 2rem 0 0;
    color: #28628F;
    font-weight: 500; }

.hvActions--lead {
  background: #c9d5defa;
  border: 2px solid #c9d5defa;
  padding: 0 0.75rem;
  font-weight: 600;
  color: black; }

.hvActions--other,
.hvActions--solicitation {
  border: 2px solid #c9d5defa;
  padding: 0 0.75rem;
  font-weight: 600;
  color: black; }

.hvActions--unclassified {
  padding-left: 2px;
  font-weight: 400; }

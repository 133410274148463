@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";
@import "global/bootstrap/mixins";
@import "global/global-mixins/global-mixins";

.visitor-demo-settings {
  margin: $baseline * 2;
  padding-bottom: $baseline;
  border-bottom: 1px dotted $gray-medium;
}

.visitor-demo-settings__subheader {
  font-size: $font-size-small;
  font-weight: 400;
  margin-bottom: $baseline * 3;

  img {
    width: 18px;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.visitor-demo-settings__categories {
  display: grid;
  grid-gap: $baseline * 4;
  grid-template-columns: repeat(auto-fill, 350px);
  margin: $baseline 0;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr 1fr;
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
  }
}

.target-audience-icon {
  font-size: $font-size-default;
  color: $red;
}

.visitor-demo-settings__row {
  display: grid;
  grid-template-columns: 40% auto;
  padding: $baseline * 1.5 0 $baseline * 3 0;
  border-top: 1px dotted $gray;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.visitor-demo--active-item {
  color: $blue;
  font-weight: 600;
  font-size: $font-size-xsmall;
  padding-left: 0 !important;
  margin-bottom: 0.5rem;
  > * {
    display: flex;
    padding: $baseline/2;
    align-items: center;
  }
  span {
    margin-left: $baseline / 2;
  }
  svg {
    font-size: $font-size-default;
  }
}

.vd--label {
  font-weight: 500;
}

.session-detail__header {
  border-bottom: none;
  margin-bottom: 0;
  border-top: #ecf1f5 1px solid;
  padding-top: 0.5rem;
  margin-left: 1rem;
  color: black; }

.session-detail__table {
  width: calc(100% - 2rem);
  margin: 1rem;
  table-layout: fixed; }
  @media (max-width: 767.98px) {
    .session-detail__table {
      width: auto; } }

.session-detail__table {
  border: 1px solid #A4ACB3;
  margin-top: 0; }

.session-detail__table th {
  background-color: #fff;
  color: black;
  border: 1px solid #A4ACB3;
  font-weight: bolder !important; }

.session-detail__table td {
  border-right: 1px solid #A4ACB3;
  border-left: 1px solid #A4ACB3; }

.session-detail__table tbody tr:nth-child(odd) {
  background-color: #ecf1f5; }

.session-detail__table tbody tr:nth-child(even) {
  background-color: #fff; }

.session-detail__table-id {
  font-weight: bold; }

.hv-page-icon {
  float: left;
  margin-right: 0.5rem !important; }

.font-hv-page {
  font-size: 1.75rem; }

.session-detail__table-pageUrl {
  color: #28628F; }
  .session-detail__table-pageUrl div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.0625rem;
    margin: auto; }

.session-detail__table-where * {
  margin-right: 0.5rem; }

.where-icon {
  color: #28628F;
  font-size: 1.75rem; }

@media (max-width: 1047.98px) {
  .session-detail__table td {
    padding: 0.5rem; } }

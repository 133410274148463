.help-menu {
  background-color: white;
  top: 4rem;
  right: -17em;
  border-left: none;
  padding-bottom: 4rem;
  width: 17em;
  height: 100%;
  z-index: 1000;
  position: fixed;
  overflow: scroll;
  transition: all 0.25s ease-in-out; }
  @media (max-width: 767.98px) {
    .help-menu {
      top: 2.5rem; } }
  .help-menu.active {
    right: 0;
    border-left: 1px #A4ACB3 solid;
    box-shadow: -1px 1px 6px #A4ACB3; }

.help-menu--header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #A4ACB3;
  padding: 0.5rem 0;
  color: #28628F;
  font-size: 1.25rem;
  font-weight: 600; }

.help-menu--close {
  cursor: pointer;
  font-size: 1em; }

.help-menu--video {
  padding: 0.5rem; }
  .help-menu--video .vgi--video {
    height: 100px; }
  .help-menu--video .vgi--title {
    font-size: 1.0625rem;
    color: #28628F;
    font-weight: 600; }
  .help-menu--video .vgi--subtitle {
    font-size: 0.875rem; }

.help-menu--section-title {
  font-size: 1.0625rem;
  color: #28628F;
  font-weight: 600; }

.help-menu--icons {
  padding: 0.5rem; }

.help-menu--icons-rows {
  display: flex;
  flex-direction: column; }
  .help-menu--icons-rows span {
    padding: 0.25rem 0;
    display: grid;
    grid-template-columns: 36px auto; }
  .help-menu--icons-rows svg {
    margin-right: 0.5rem; }

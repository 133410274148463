@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";

.visitor-locations {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1rem;
}

.international-visitors {
  margin-top: $baseline * 2;
}

// Maps
.map-display {
  height: 350px;
}

.mapboxgl-canvas {
  position: unset !important;
  height: 300px;
}

.map-item {
  position: relative;
  color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  top: -10px;
  left: -10px;
  font-size: $font-size-xsmall;
}

.map-item__cluster {
  background-color: $red;
  box-shadow: 0 0 0 4px #f1afaf;
  width: 16px;
  height: 16px;
  top: -8px;
  left: -8px;
}

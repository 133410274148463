.dotter {
    width: 70px;
    text-align: start;
    padding: 0.5rem 0;
}

.dotter > div {
    width: 10px;
    height: 10px;
    background-color: #28628f;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.dotter .dot1 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.dotter .dot2 {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.dotter .dot2 {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

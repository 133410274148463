@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";
@import "global/bootstrap/mixins";
@import "global/global-mixins/global-mixins";
// ------------- Print Friendly Styling----------

@media print {
  h4 {
    margin-top: 0.2rem;
    margin-bottom: 0.3rem;
  }
  p {
    margin: 0;
  }
  .table th {
    background-color: #ecf1f5 !important;
    -webkit-print-color-adjust: exact;
    line-height: 0.8;
  }
  tbody tr {
    line-height: 1;
  }
  .page-utility {
    display: none;
  }
  .container--site-header__global {
    display: none;
  }
  .usabilla_live_button_container {
    display: none;
  }
  .site-footer {
    display: none;
  }
  .site-wrap {
    background-color: white;
    margin: 0;
  }
  .page-header__header {
    display: none;
  }
  .thomas-pagination {
    display: none;
  }

  .table-container {
    thead td {
      height: 60px;
    }
  }

  h4 {
    margin-left: 1rem;
  }

  .pdf_second-page-subheader {
    position: absolute;
    // bottom: 1850px;
    left: 30px;
    top: 10px;
    h2 {
      margin-bottom: 0;
      font-size: 1.65rem;
    }
    p {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  @page {
    margin: 0.5rem;
    size: 8.5in 11in;
  }
}

//------ NEW STYLING

.pdf_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #03405f;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: white;
  padding: 0.3rem;
}

table {
  width: 100%;
  margin-top: 0.5rem;
}

.pdf_company-name {
  color: #03405f;
  margin: 1rem 1rem 0rem 1rem;
  font-size: 30px;
  text-align: center;
}

.pdf_company-name__subheader {
  color: #03405f;
  font-size: 20px;
  text-align: center;
}

.pdf_tbody-td-center {
  border: 1px solid #ecf1f5;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0.2rem;
}

.pdf_td-headers {
  margin-left: 1rem;
  display: inline;
  color: #00334d;
}

.page-break-after {
  page-break-after: always;
}

.page-break-before {
  page-break-before: always;
}

.pdf-second-page-content {
  position: absolute;
  top: 1100px;
  width: 100%;
  margin-top: 40px;
}

.pdf_custom-session-graph--wrapper {
  height: 255px;
  width: 800px;
  zoom: 0.85;
  padding: 0.5rem;
}

.pdf_created-by {
  margin-left: 1rem;
}

.pdf_thomasnet-copyright {
  margin-right: 1rem;
}

/* Header and Footer  */
.pdf_header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #03405f;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: white;
  padding: 0.5rem;
  z-index: 100;
  img {
    height: 45px;
    padding: 0.1rem;
  }
  h3 {
    color: white;
    text-align: bottom;
    margin-bottom: 0.05rem;
    font-weight: 400;
  }
}

/* First Page Content */

.pdf_caption-background {
  background-image: url("https://cdn40.thomasnet.com/img40/hero-bg.jpg");
  background-size: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 200;
  height: 8%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  h3 {
    color: white;
    text-align: bottom;
    margin-bottom: 0.05rem;
    font-weight: 400;
  }
  img {
    display: flex;
    padding: 0.1rem;
  }
}

.pdf_first-page-content {
  padding: 0.5rem;
  position: absolute;
  width: 100%;
  top: 90px;
}

.pdf_campaign-period {
  font-size: 20px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  padding-bottom: 20px;
  p {
    font-size: 18px;
  }
}

.pdf_row-container_medium {
  display: flex;
  width: 100%;
  height: 300px;
  margin-bottom: 1rem;
  h2 {
    color: #00334d;
    padding: 0.5rem 0 0 0;
  }
  h3 {
    color: $gray-dark;
  }
  .pdf_pvs-creatives-container {
    display: flex;
    .pdf_pvs-creative-0 {
      height: 250px;
      width: 120px;
      padding: 0.3rem;
    }
    .pdf_pvs-creative-1 {
      height: 70px;
      width: 275px;
      padding: 0.3rem;
    }
  }
  .pdf_description {
    font-size: $font-size-small;
    color: $gray-dark;
    padding-left: 1rem;
  }
}

.pdf_row-container_small {
  display: flex;
  width: 100%;
  height: 215px;
  margin-bottom: 1rem;
  h2 {
    color: #00334d;
    padding: 0.5rem 0 0 0;
  }
  h3 {
    color: $gray-dark;
  }
  .pdf_pvs-creatives-container {
    display: flex;
    .pdf_pvs-creative-0 {
      height: 250px;
      width: 120px;
      padding: 0.3rem;
    }
    .pdf_pvs-creative-1 {
      height: 70px;
      width: 275px;
      padding: 0.3rem;
    }
  }
  .pdf_description {
    font-size: $font-size-small;
    color: $gray-dark;
  }
}

.pdf_impressions-and-clicks-container {
  padding: 1rem;
  display: flex;
  width: 20%;
  justify-content: space-evenly;
  .pdf_impressions {
    text-align: center;
    h2 {
      margin: 0;
    }
  }
  .pdf_clicks {
    text-align: center;
    h2 {
      margin: 0;
    }
  }
}

.pdf_audience-by-industry__container {
  display: flex;
  width: 100%;
  .pdf_topcountcard-pie-desc {
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: flex-start;
    padding: 0.5rem;
    .pdf_topcountcard-piechart {
      display: flex;
      width: 20%;
      svg {
        margin: 0 !important;
        margin-right: 2rem;
      }
    }
    .pdf_count-card-row-container {
      padding: 1.5rem;
      width: 50%;
    }
  }
}

.pdf_disclaimer {
  display: flex;
  flex-flow: column;
  padding: 1rem;
  .pdf_disclaimer-content {
    display: flex;
    flex-flow: row;
  }
}

/* Common Style  */
.gray-border {
  border: 1px solid #a4acb3;
}

.display-flex-row {
  flex-flow: row;
}

.display-flex-col {
  flex-flow: column;
}

.font-size-xxs {
  font-size: 10px;
}

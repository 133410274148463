@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";
@import "global/bootstrap/mixins";
@import "global/global-mixins/global-mixins";

.p-clickable {
  color: $blue !important;
  font-weight: 500;
}

.campaign-metrics--datepicker {
  position: unset !important;
  margin-left: auto;

  .date-picker--dropdown {
    &.active {
      top: 4.25rem;
      right: 1.5rem;
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}

.campaigns-dash-header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  justify-content: space-between;
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
  h3 {
    display: flex;
    align-items: flex-end;
    margin-right: 1rem;
  }
  p {
    color: $gray-medium;
  }
}

.campaigns-dash_companyName {
  margin: 0;
}

.campaigns-dash-metrics {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  color: #616668;
  font-weight: 400;
  .item-2 {
    max-width: 50%;
  }

  @media (max-width: 767px) {
    justify-content: center;
    .item-2 {
      max-width: 100%;
    }
  }

  @media (min-width: 870px) {
    .item-2 {
      max-width: 20%;
    }
  }
  @media (min-width: 1000px) {
    .item-2 {
      max-width: 30%;
    }
  }
  @media (min-width: 1200px) {
    .item-2 {
      max-width: 40%;
    }
  }
  @media (min-width: 1300px) {
    .item-2 {
      max-width: 50%;
    }
  }
}

.metrics-items {
  display: flex;
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
}

.metrics-item {
  text-align: center;
  padding: 0 2rem;
  &:first-child {
    padding-left: 0;
    display: flex;
  }
}
.metrics-item-line {
  width: 1px;
  background: #dfe6ec;
  height: 50%;
}

.campaigns-dash-map {
  .map-display {
    height: 275px;
  }
}

.campaigns-card:first-child {
  margin: 0.75rem 0;
  height: auto;
  width: 100%;
  // flex: 1;
}

.campaigns-card:last-child {
  width: 49%;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.metrics-item-cluster {
  margin-left: 10px;
  text-align: left;
  color: #616668;
}

.campaigns-card__impressions-and-clicks {
  padding: 1.5rem 0;
  margin-bottom: 2rem;
  width: 100%;
}

svg.recharts-surface {
  transform: scale(1.02);
}

.description {
  @include media-breakpoint-up(sm) {
    max-width: 35%;
  }
}

.funnel-analysis {
  display: grid;
  line-height: 1.5;
  grid-template-columns: 1fr 1fr; }
  @media (max-width: 1047.98px) {
    .funnel-analysis {
      grid-template-columns: 1fr; } }

.funnel-analysis--summary {
  padding: 0.5rem;
  padding-bottom: 1rem;
  border-right: 1px solid #ecf1f5;
  border-bottom: 1px solid #ecf1f5;
  -ms-grid-column: 1;
  -ms-grid-row: 1; }

.funnel-analysis--summary-item span {
  padding-left: 5px;
  font-weight: 600; }

.funnel-analysis--summary-item .pageUrl {
  color: #28628F; }

.funnel-analysis--searchterms {
  padding: 0.5rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ecf1f5;
  -ms-grid-column: 2;
  -ms-grid-row: 1; }
  @media (max-width: 1047.98px) {
    .funnel-analysis--searchterms {
      padding-left: 0.5rem; } }

.searchterms-analysis {
  padding-left: 0 !important; }
  .searchterms-analysis .top-countcard--ref {
    color: black; }

.funnel-analysis--sessions {
  font-weight: 600; }
  .funnel-analysis--sessions span {
    padding-right: 2rem;
    float: right; }

.funnel-analysis--referrers {
  margin-top: 1rem;
  padding: 0.5rem;
  -ms-grid-column: 1;
  -ms-grid-row: 2; }

.funnel-analysis--pie {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-grid-column: 2;
  -ms-grid-row: 2; }

.top-countcard__referrers {
  padding-left: 0 !important; }
  .top-countcard__referrers .top-countcard--color {
    width: 35px;
    height: 20px;
    margin-right: 10px;
    margin-top: 7px; }
  .top-countcard__referrers .top-countcard--fill {
    width: calc(100% - 66px) !important; }

.funnel-analysis-top-card {
  border: none;
  padding-left: 0; }

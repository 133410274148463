/* ----------------------------
    Dropdown Overrides
---------------------------- */
.rw-widget > .rw-widget-container {
  border-radius: 0; }

.rw-input,
.rw-placeholder,
.rw-list-option,
.rw-state-focus {
  color: #28628F !important; }

.rw-multiselect-tag {
  margin: 4px; }

.rw-list-option {
  padding: 0.25rem;
  padding-left: 12px;
  line-height: 1.25em;
  font-size: 15px; }

.filter-target .rw-list-option {
  padding: 0;
  margin: 0; }

.rw-list-option.rw-state-selected {
  background-color: #67c9fa;
  border-color: #67c9fa; }

.rw-list-option.rw-state-selected:hover {
  background-color: #a6d7f0;
  border-color: #a6d7f0; }

.rw-i-caret-down:before {
  content: url(/img/ico-select.svg);
  position: absolute;
  top: 0.5rem; }

.rw-multiselect .rw-btn-select:before {
  content: url(/img/ico-select.svg);
  position: absolute;
  top: 0.25rem; }

.rw-multiselect .rw-i-caret-down:before {
  content: unset; }

.rw-multiselect-tag-btn:before {
  content: unset;
  display: none; }

.rw-state-disabled,
.rw-state-disabled:hover {
  background: #f3f6f9 !important;
  background-color: #f3f6f9 !important; }

.rw-list {
  max-height: 400px; }

.rw-list-optgroup {
  padding-left: 3px;
  padding-top: 3px;
  background-color: #ecf1f5;
  font-size: 17px; }

.rw-subheader {
  color: black;
  padding: 0.25rem;
  font-weight: bold;
  display: block;
  background-color: white !important;
  cursor: text;
  line-height: 1.75em;
  font-size: 15px; }

.rw-subheader-item {
  padding: 0.25rem;
  padding-left: 12px;
  line-height: 1.15em;
  font-size: 15px;
  display: flex; }

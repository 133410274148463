.chart-title {
  margin: 0.5rem 15px; }
  @media (max-width: 767.98px) {
    .chart-title {
      text-align: center; } }

.piechart-wrapper {
  display: -ms-grid;
  -ms-grid-columns: 180px 1fr 1fr;
  display: grid;
  grid-template-columns: 180px 1fr 1fr; }
  .piechart-wrapper svg {
    margin: 15px; }
  .piechart-wrapper .no-marg {
    margin: 0;
    top: 0;
    position: absolute; }
  @media (max-width: 767.98px) {
    .piechart-wrapper {
      grid-template-columns: 1fr;
      margin: 0 auto; }
      .piechart-wrapper svg {
        margin: 0.5rem auto; } }
  .piechart-wrapper div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .piechart-wrapper div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .piechart-wrapper div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }

.piechart-legend {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0 0.5rem 1rem;
  word-break: break-word; }
  @media (max-width: 767.98px) {
    .piechart-legend {
      margin: 0 1rem; } }

.legend-item {
  display: flex;
  align-items: baseline; }

.legend-item--square {
  height: 10px;
  min-width: 10px;
  margin-right: 0.5rem;
  float: left; }

.legend-item--label {
  font-size: 15px;
  transition: 0.4s; }
  .legend-item--label.active {
    font-size: 15px;
    border: 0.5px solid #dfe6ec;
    box-shadow: 1px 1px black; }
  @media (max-height: 46em) and (min-width: 65em) {
    .legend-item--label {
      font-size: 13px; } }

.large-pi {
  grid-template-columns: 1fr; }

.piechart-loading-donut {
  width: 150px;
  height: 150px !important;
  margin: 0.5rem auto;
  position: relative; }

.legend-loading {
  margin-right: 1rem; }

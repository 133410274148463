.results-control {
  display: flex;
  margin-bottom: 0.5rem; }

.results-control__display-amount {
  color: #28628F;
  font-size: 0.875rem;
  padding: 0.5rem 1rem 0.5rem 0; }
  .results-control__display-amount span {
    cursor: pointer;
    font-weight: bold; }
    .results-control__display-amount span.active {
      color: #6CB33F; }

.results-control__view-type {
  color: #28628F;
  font-size: 0.875rem;
  padding: 0.5rem 1rem 0.5rem 1rem; }
  .results-control__view-type span {
    cursor: pointer; }
    .results-control__view-type span.active {
      border-bottom: 1px #6CB33F solid;
      font-weight: bold; }

.results-control__utility-icons {
  display: flex;
  font-size: 0.875rem;
  padding: 0.5rem 0 0.5rem 0.5rem;
  margin-left: auto; }
  .results-control__utility-icons * {
    padding-left: 0.5rem; }
  .results-control__utility-icons .spinner > div {
    padding-left: 0.1rem;
    width: 3px; }
  .results-control__utility-icons .spinner {
    display: inline;
    height: 20px; }

.session-depth--visitor-information {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.sd-visitor-information--row {
  display: flex;
  padding: 0 0.5rem;
  font-size: 1.0625rem; }
  .sd-visitor-information--row .title {
    margin-right: 0.5rem; }
  .sd-visitor-information--row span {
    font-weight: bold; }

.session-path-details--row {
  display: grid;
  grid-template-columns: 5% 25% 60% 10%;
  font-size: 1.0625rem; }
  .session-path-details--row:nth-child(odd) {
    background-color: #fff; }
  .session-path-details--row:nth-child(even) {
    background-color: #ecf1f5; }
  .session-path-details--row > * {
    padding: 0.5rem;
    border-right: 1px solid #A4ACB3; }
    .session-path-details--row > *:last-child {
      border-right: none; }

.sd-pageNum {
  font-weight: bold; }

.sd-pageUrl {
  color: #28628F;
  font-weight: 400; }

.session-path-details {
  margin: 0.5rem;
  border: 1px solid #A4ACB3;
  margin-right: 1rem; }

@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";
@import "global/bootstrap/mixins";
@import "global/global-mixins/global-mixins";

.logout-button {
  cursor: pointer;
  color: white;
  width: 10rem;
  padding: 1rem;
  margin: 0;
  z-index: 100000;
  &:hover {
    background-color: #03405f;
  }

  @include media-breakpoint-down(sm) {
    padding: 0.25rem;
  }
}

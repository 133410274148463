.logout-button {
  cursor: pointer;
  color: white;
  width: 10rem;
  padding: 1rem;
  margin: 0;
  z-index: 100000; }
  .logout-button:hover {
    background-color: #03405f; }
  @media (max-width: 767.98px) {
    .logout-button {
      padding: 0.25rem; } }

.thomas-icon {
  color: #28628F;
  font-size: 1.75rem; }

.icon-red {
  color: #BC4239; }

.icon-green {
  color: #6CB33F; }

.icon-lightblue {
  color: #28628F;
  opacity: 0.5; }

.icon_funnel-analysis {
  position: relative;
  width: 23px;
  bottom: 2px; }

.icon-name-description {
  color: #212529;
  font-size: 1rem; }

.funnel-analysis {
  display: grid;
  line-height: 1.5;
  grid-template-columns: 1fr 1fr; }
  @media (max-width: 1047.98px) {
    .funnel-analysis {
      grid-template-columns: 1fr; } }

.funnel-analysis--summary {
  padding: 0.5rem;
  padding-bottom: 1rem;
  border-right: 1px solid #ecf1f5;
  border-bottom: 1px solid #ecf1f5;
  -ms-grid-column: 1;
  -ms-grid-row: 1; }

.funnel-analysis--summary-item span {
  padding-left: 5px;
  font-weight: 600; }

.funnel-analysis--summary-item .pageUrl {
  color: #28628F; }

.funnel-analysis--searchterms {
  padding: 0.5rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ecf1f5;
  -ms-grid-column: 2;
  -ms-grid-row: 1; }
  @media (max-width: 1047.98px) {
    .funnel-analysis--searchterms {
      padding-left: 0.5rem; } }

.searchterms-analysis {
  padding-left: 0 !important; }
  .searchterms-analysis .top-countcard--ref {
    color: black; }

.funnel-analysis--sessions {
  font-weight: 600; }
  .funnel-analysis--sessions span {
    padding-right: 2rem;
    float: right; }

.funnel-analysis--referrers {
  margin-top: 1rem;
  padding: 0.5rem;
  -ms-grid-column: 1;
  -ms-grid-row: 2; }

.funnel-analysis--pie {
  padding: 0.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -ms-grid-column: 2;
  -ms-grid-row: 2; }

.top-countcard__referrers {
  padding-left: 0 !important; }
  .top-countcard__referrers .top-countcard--color {
    width: 35px;
    height: 20px;
    margin-right: 10px;
    margin-top: 7px; }
  .top-countcard__referrers .top-countcard--fill {
    width: calc(100% - 66px) !important; }

.funnel-analysis-top-card {
  border: none;
  padding-left: 0; }

.top-countcard {
  padding: 1rem;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 100; }

.top-countcard--list {
  padding-left: 0; }

.top-countcard--row {
  display: -webkit-flex;
  display: flex;
  position: relative; }

.top-countcard--order {
  color: #616668;
  padding-right: 0.5rem;
  width: 38px; }

.top-countcard--order-color {
  width: 1rem;
  height: 1rem;
  margin: 0.25rem;
  margin-top: 0.5rem; }

.top-countcard--ref {
  color: #188dbf;
  padding-right: 0.5rem;
  position: absolute;
  background: white;
  z-index: 2; }

.top-countcard--fill {
  color: #A4ACB3;
  position: absolute;
  top: 15px;
  z-index: 1;
  border-top: 1px solid #dfe6ec;
  width: calc(100% - 56px); }

.top-countcard--total {
  font-weight: bold;
  padding-left: 0.5rem;
  position: absolute;
  right: 0.25rem;
  background: white;
  z-index: 2; }

.top-countcard--view-all {
  color: #28628F;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: auto; }
  .top-countcard--view-all :first-child {
    margin-left: 0.5rem; }

.topcountcard-piechart {
  float: left; }
  .topcountcard-piechart svg {
    margin: 0 !important;
    margin-right: 2rem; }

.search-term-show-more {
  color: #28628F;
  font-size: 1.0625rem;
  padding-top: 0.5rem;
  font-weight: 500;
  cursor: pointer; }

.topcountcard-loading .piechart-loading-donut {
  margin: 0;
  margin-right: 1rem;
  float: left; }

.topcountcard-loading .piechart-legend {
  height: 140px; }

.loading-2line-block {
  position: absolute;
  width: 100%; }

.chart-title {
  margin: 0.5rem 15px; }
  @media (max-width: 767.98px) {
    .chart-title {
      text-align: center; } }

.piechart-wrapper {
  display: -ms-grid;
  -ms-grid-columns: 180px 1fr 1fr;
  display: grid;
  grid-template-columns: 180px 1fr 1fr; }
  .piechart-wrapper svg {
    margin: 15px; }
  .piechart-wrapper .no-marg {
    margin: 0;
    top: 0;
    position: absolute; }
  @media (max-width: 767.98px) {
    .piechart-wrapper {
      grid-template-columns: 1fr;
      margin: 0 auto; }
      .piechart-wrapper svg {
        margin: 0.5rem auto; } }
  .piechart-wrapper div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .piechart-wrapper div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .piechart-wrapper div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }

.piechart-legend {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0.5rem 0 0.5rem 1rem;
  word-break: break-word; }
  @media (max-width: 767.98px) {
    .piechart-legend {
      margin: 0 1rem; } }

.legend-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline; }

.legend-item--square {
  height: 10px;
  min-width: 10px;
  margin-right: 0.5rem;
  float: left; }

.legend-item--label {
  font-size: 15px;
  transition: 0.4s; }
  .legend-item--label.active {
    font-size: 15px;
    border: 0.5px solid #dfe6ec;
    box-shadow: 1px 1px black; }
  @media (max-height: 46em) and (min-width: 65em) {
    .legend-item--label {
      font-size: 13px; } }

.large-pi {
  grid-template-columns: 1fr; }

.piechart-loading-donut {
  width: 150px;
  height: 150px !important;
  margin: 0.5rem auto;
  position: relative; }

.legend-loading {
  margin-right: 1rem; }

.session-depth--visitor-information {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.sd-visitor-information--row {
  display: -webkit-flex;
  display: flex;
  padding: 0 0.5rem;
  font-size: 1.0625rem; }
  .sd-visitor-information--row .title {
    margin-right: 0.5rem; }
  .sd-visitor-information--row span {
    font-weight: bold; }

.session-path-details--row {
  display: grid;
  grid-template-columns: 5% 25% 60% 10%;
  font-size: 1.0625rem; }
  .session-path-details--row:nth-child(odd) {
    background-color: #fff; }
  .session-path-details--row:nth-child(even) {
    background-color: #ecf1f5; }
  .session-path-details--row > * {
    padding: 0.5rem;
    border-right: 1px solid #A4ACB3; }
    .session-path-details--row > *:last-child {
      border-right: none; }

.sd-pageNum {
  font-weight: bold; }

.sd-pageUrl {
  color: #28628F;
  font-weight: 400; }

.session-path-details {
  margin: 0.5rem;
  border: 1px solid #A4ACB3;
  margin-right: 1rem; }

.session-depth {
  padding: 1rem;
  width: 100%; }

.session-graph {
  width: 100%;
  height: 200px; }

.session-graph--wrapper {
  width: 100%;
  height: 100%; }
  .session-graph--wrapper svg text {
    font-size: 11px !important;
    fill: #A4ACB3 !important; }

.impressions {
  border: none !important;
  padding: 0 !important; }

@media (min-width: 1048px) {
  .custom-session-graph--wrapper {
    height: 400px;
    width: auto; } }

@media (max-width: 575.98px) {
  .custom-session-graph--wrapper {
    height: 300px;
    width: auto; }
  h4.chart-title {
    margin: 0 1.5rem; }
  h5.chart-legends {
    font-size: 0.875rem;
    margin: 0 1.5rem; } }

.title-and-legends-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: start;
          justify-content: start; }

.chart-legends {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  font-weight: 400;
  font-size: 1.0625rem;
  margin: 1rem;
  color: #616668; }

h5.chart-legends > div {
  margin-right: 1.5rem; }

.chart-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1.5rem; }

.chart-hero {
  padding: 0.5rem;
  margin-bottom: 2rem; }

.section-header {
  display: -webkit-flex;
  display: flex;
  margin: 0.5rem;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.chart-hero--info {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.chart-hero--customize {
  margin-right: 1.5rem; }

.chart-hero--title {
  display: -webkit-flex;
  display: flex;
  margin-left: 1rem;
  margin-right: 3rem;
  font-size: 1.25rem; }
  .chart-hero--title > * {
    padding: 0.5rem; }
    .chart-hero--title > * span {
      font-weight: bold; }

.chart-hero--legend {
  display: -webkit-flex;
  display: flex;
  margin-left: 1rem; }

.chart-hero--legend-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.5rem; }

.sk-circle {
  margin: 100px auto;
  width: 150px;
  height: 150px;
  position: relative; }

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #28628F;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg); }

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg); }

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg); }

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg); }

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg); }

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg); }

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg); }

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg); }

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg); }

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.total-sessions-graph {
  width: 100%;
  height: 480px; }

.depth-length-loader {
  height: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .depth-length-loader .sk-circle {
    width: 120px;
    height: 120px;
    margin: 20px auto 0 auto; }

.graph-wrapper {
  width: 100%;
  height: 100%; }
  .graph-wrapper svg text {
    font-size: 16px !important;
    fill: #A4ACB3 !important; }
  .graph-wrapper svg line {
    stroke: #A4ACB3 !important; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 70px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 30px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day {
  cursor: pointer;
}

.react-datepicker__day:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  background-color: #216ba5;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: "\D7";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 7px;
  text-align: center;
  top: 50%;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

/* ----------------------------
    Component specific styles
---------------------------- */
.date-picker {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.date-picker--header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.date-picker--header-title {
  color: #28628F;
  font-size: 1.25rem;
  font-weight: bold; }

.date-picker--display {
  display: -webkit-flex;
  display: flex;
  z-index: 11;
  margin-left: 10px;
  background-color: white;
  border: 1px solid #dddddd;
  cursor: pointer; }
  .date-picker--display.active {
    border-bottom: 1px solid white; }

.date-picker--display-range {
  margin: 10px;
  color: #00334D; }

.date-picker--display-arrow {
  padding: 10px;
  background-color: #dfe6ec; }
  .date-picker--display-arrow.active {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.date-picker--months {
  text-align: center; }

.date-picker--actions {
  text-align: center; }

.date-picker--actions-dates {
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  border-bottom: 1px dotted grey;
  padding-bottom: 10px; }

.grid1 {
  grid-column: 1;
  grid-row: 1; }

.grid2 {
  grid-column: 1;
  grid-row: 2; }

.date-picker--input {
  width: 100px;
  margin: auto;
  padding: 5px; }

.input-error {
  box-shadow: 0px 0px 8px 3px #BC4239; }

.date-picker--label {
  font-weight: 400;
  margin: auto; }

.date-picker--actions-error {
  font-size: 0.875rem;
  font-weight: bold;
  color: #BC4239; }

.date-picker--actions-buttons {
  margin: 10px 0px; }
  .date-picker--actions-buttons button {
    margin: 5px; }

.date-picker--dropdown {
  display: none; }
  .date-picker--dropdown.active {
    display: block;
    position: absolute;
    top: 2.75rem;
    right: 0;
    width: 700px;
    z-index: 10;
    background-color: white;
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 1rem;
    border: 1px solid #dddddd;
    box-shadow: 0px 2px 2px #dddddd; }
    @media (max-height: 46em) and (min-width: 65em) {
      .date-picker--dropdown.active {
        top: 3rem; } }
    @media (max-height: 40em) and (min-width: 65em) {
      .date-picker--dropdown.active {
        top: 3.25rem; } }
    @media (max-width: 767.98px) {
      .date-picker--dropdown.active {
        grid-template-columns: 1fr;
        grid-template-rows: 2fr 1fr;
        width: unset; } }

/* ----------------------------
    Datepicker Overrides
---------------------------- */
.react-datepicker {
  border: none;
  font-family: "Whitney A", "Whitney B", "Helvetica Neue", Arial, sans-serif; }

.react-datepicker__day {
  border: 0.5px solid lightgrey;
  margin: 0; }

.react-datepicker__day:hover {
  border-radius: 0;
  background-color: #dfe6ec; }

.react-datepicker__day--selected,
.react-datepicker__day--in-range-start,
.react-datepicker__day--in-range-end,
.react-datepicker__day--in-range {
  border-radius: 0;
  background-color: #3CB4E7; }

.react-datepicker__day--outside-month {
  color: transparent;
  background-color: transparent;
  pointer-events: none; }

.react-datepicker__navigation--previous {
  border-right-color: #28628F; }

.react-datepicker__navigation--next {
  border-left-color: #28628F; }

.react-datepicker__header {
  padding: 0;
  margin: 0.4rem;
  margin-bottom: 0;
  border: 0.5px solid lightgrey;
  background-color: #dfe6ec;
  border-radius: 0; }

.react-datepicker__current-month {
  color: #28628F; }

.react-datepicker__month {
  margin: 0;
  padding: 0.4rem;
  padding-top: 0; }

.react-datepicker__month-container:last-child {
  padding-left: 25px; }

.react-datepicker__day-names,
.react-datepicker__triangle {
  display: none; }

@media (max-width: 767.98px) {
  .react-datepicker {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; } }

.page-header {
  position: relative; }

.help-img {
  font-size: 2.5rem;
  position: absolute;
  right: 0.75rem;
  top: 6.5rem;
  color: #6CB33F;
  cursor: pointer; }
  @media (max-width: 1047.98px) {
    .help-img {
      top: 8.5rem;
      right: 0.75rem; } }
  @media (max-width: 767.98px) {
    .help-img {
      top: 6rem;
      right: 0.25rem; } }
  @media (max-width: 575.98px) {
    .help-img {
      font-size: 1.5rem;
      top: 6rem;
      right: 0.25rem; } }

.page-header--icon {
  width: 2.5rem !important;
  height: 2.5rem !important;
  padding-right: 10px; }

.page-header--sub {
  font-size: 1.0625rem;
  color: #616668;
  font-weight: 200; }

.help-menu {
  background-color: white;
  top: 4rem;
  right: -17em;
  border-left: none;
  padding-bottom: 4rem;
  width: 17em;
  height: 100%;
  z-index: 1000;
  position: fixed;
  overflow: scroll;
  transition: all 0.25s ease-in-out; }
  @media (max-width: 767.98px) {
    .help-menu {
      top: 2.5rem; } }
  .help-menu.active {
    right: 0;
    border-left: 1px #A4ACB3 solid;
    box-shadow: -1px 1px 6px #A4ACB3; }

.help-menu--header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #A4ACB3;
  padding: 0.5rem 0;
  color: #28628F;
  font-size: 1.25rem;
  font-weight: 600; }

.help-menu--close {
  cursor: pointer;
  font-size: 1em; }

.help-menu--video {
  padding: 0.5rem; }
  .help-menu--video .vgi--video {
    height: 100px; }
  .help-menu--video .vgi--title {
    font-size: 1.0625rem;
    color: #28628F;
    font-weight: 600; }
  .help-menu--video .vgi--subtitle {
    font-size: 0.875rem; }

.help-menu--section-title {
  font-size: 1.0625rem;
  color: #28628F;
  font-weight: 600; }

.help-menu--icons {
  padding: 0.5rem; }

.help-menu--icons-rows {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .help-menu--icons-rows span {
    padding: 0.25rem 0;
    display: grid;
    grid-template-columns: 36px auto; }
  .help-menu--icons-rows svg {
    margin-right: 0.5rem; }

.vgi--video {
  background-color: #ced2d6;
  height: 12rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .vgi--video img {
    cursor: pointer; }

.vgi--title {
  color: #28628F;
  font-size: 1.75rem;
  padding: 0.5rem 0; }

.vgi--subtitle {
  color: #616668;
  font-size: 1.25rem;
  padding: 0.5rem 0;
  padding-top: 0; }

.table-row-details {
  grid-area: 2/1/2/8;
  background-color: #fff;
  border-top: 1px dashed #dfe6ec;
  border-bottom: 1px dashed #dfe6ec;
  -webkit-align-items: start !important;
          align-items: start !important;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 1rem !important;
  -ms-grid-column: 1 !important;
  -ms-grid-row: 2 !important;
  -ms-grid-column-span: 7 !important; }
  .table-row-details:hover {
    background-color: #fff;
    cursor: default; }

.table-row-details--header {
  padding: 0.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  width: 100%; }

.table-row-details--close {
  float: right;
  color: #28628F;
  font-weight: 800;
  padding: 0 1rem;
  cursor: pointer; }

.table-row-details--content {
  width: 100%;
  font-size: 1.25rem; }

.table-details--rows {
  padding: 0.5rem;
  padding-right: 1.5rem; }

.table-details--row {
  padding: 0.25rem;
  display: -webkit-flex;
  display: flex;
  font-size: 1.0625rem; }
  .table-details--row:nth-child(odd) {
    background-color: #ecf1f5; }
  .table-details--row:nth-child(even) {
    background-color: #fff; }
  .table-details--row b {
    width: 150px; }

.thomas-table {
  margin-top: 0.5rem;
  margin-bottom: 2rem; }
  .thomas-table a:hover {
    color: unset !important;
    text-decoration: none !important; }

.thomas-table__grid {
  display: -ms-grid;
  -ms-grid-columns: 20% 15% 13% 12% 10% 6% 12% 12%;
  display: grid;
  grid-template-columns: 20% 15% 13% 12% 10% 6% 12% 12%; }
  .thomas-table__grid div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(4) {
    -ms-grid-column: 4;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(5) {
    -ms-grid-column: 5;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(6) {
    -ms-grid-column: 6;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(7) {
    -ms-grid-column: 7;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(8) {
    -ms-grid-column: 8;
    -ms-grid-row: 1; }
  .thomas-table__grid > * {
    padding: 0.33333rem;
    border-left: 1px solid #A4ACB3;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .thomas-table__grid > * :first-child {
      -webkit-justify-content: start;
              justify-content: start; }

.grid-col-3 {
  grid-template-columns: repeat(3, 1fr); }

.thomas-table--header {
  background-color: #dfe6ec;
  color: black;
  border-top: 1px solid #A4ACB3;
  border-right: 1px solid #A4ACB3;
  font-weight: bolder !important; }
  .thomas-table--header > *:first-child {
    -webkit-justify-content: start;
            justify-content: start; }

.thomas-table--row {
  color: #616668;
  transition: 0.2s;
  border-right: 1px solid #A4ACB3;
  word-break: break-all;
  word-break: break-word; }
  .thomas-table--row:nth-child(odd) {
    background-color: #ecf1f5; }
  .thomas-table--row:nth-child(even) {
    background-color: #fff; }
  .thomas-table--row:last-child {
    border-bottom: 1px solid #A4ACB3; }

.thomas-row-clickable:hover {
  background-color: #dfe6ec;
  cursor: pointer; }

.thomas-table--show-more {
  color: #28628F;
  font-weight: bold;
  font-size: 0.875rem;
  -webkit-justify-content: start;
          justify-content: start;
  padding: 0.5rem 0.33333rem; }
  .thomas-table--show-more span:first-child {
    padding-right: 0.5rem; }

.thomas-table--title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.thomas-table--icons {
  color: #28628F;
  font-weight: bold;
  padding-top: 0.5rem; }
  .thomas-table--icons * {
    margin-right: 0.5rem; }

.thomas-table__title {
  color: #28628F;
  font-weight: 500;
  display: block; }

.thomas-table__funnelAnalysis {
  color: #28628F; }

.thomas-table__pagesViewed,
.thomas-table__sessionCount {
  -webkit-justify-content: center;
          justify-content: center; }

.table-cell__with-icons {
  padding-right: 0.5rem; }

.thomas-table__mobile {
  border: 1px solid #A4ACB3; }

.thomas-table__mobile--item {
  padding: 1rem 0; }
  .thomas-table__mobile--item:nth-child(odd) {
    background-color: #fff; }
  .thomas-table__mobile--item:nth-child(even) {
    background-color: #ecf1f5; }
  .thomas-table__mobile--item:hover {
    background-color: #eaf5e3;
    cursor: pointer; }

.thomas-table__mobile--row {
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  font-size: 0.875rem;
  padding: 0.125rem 0.5rem; }
  .thomas-table__mobile--row :last-child {
    font-weight: bold; }
  .thomas-table__mobile--row:first-child :last-child {
    color: #28628F; }

.row-loader {
  height: 36px !important;
  grid-column: 1 / 4; }

.analytics-table .thomas-table__url * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.0625rem; }

.analytics-table .thomas-table__url span {
  margin-left: 0.5rem !important; }

.analytics-table .thomas-table--title__sortable {
  text-decoration: underline;
  color: #28628F;
  cursor: pointer; }

.arrow-asc {
  padding: 0 0.25rem;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.arrow-desc {
  padding: 0 0.25rem; }

.rfi-submission {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: #fff;
  margin-bottom: 0;
  padding-bottom: 0; }

.rfi-submission--title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.25rem;
  font-weight: 900;
  margin-bottom: 1rem; }

.rfi-submission--logo {
  width: 32px;
  margin-right: 0.5rem; }

.rfi-submission--rows {
  border: 1px solid #A4ACB3;
  margin-bottom: 1rem; }

.rfi-submission--row {
  padding: 0.25rem;
  display: grid;
  grid-template-columns: 175px auto; }
  .rfi-submission--row:nth-child(odd) {
    background-color: #fff; }
  .rfi-submission--row:nth-child(even) {
    background-color: #ecf1f5; }

.rfi-details--header {
  float: left; }

.hvActions-rfi-title {
  padding-left: 2.25rem; }

.hvActions-rfi {
  margin: 0.5rem;
  padding: 0; }

.hvActions-rfi-rows {
  border: none;
  margin-right: 1rem; }

.hvActions-rfi-row {
  padding-left: 1.5rem;
  font-size: 1.0625rem; }
  .hvActions-rfi-row:nth-child(even) {
    background-color: #fff; }
  .hvActions-rfi-row:nth-child(odd) {
    background-color: #ecf1f5; }
  .hvActions-rfi-row b {
    font-weight: 300; }
  .hvActions-rfi-row div {
    font-weight: 500; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .hvActions-rfi-row {
      display: -webkit-flex;
      display: flex; }
      .hvActions-rfi-row b {
        -webkit-flex: 1 1;
                flex: 1 1; }
      .hvActions-rfi-row div {
        -webkit-flex: 5 1;
                flex: 5 1; } }

.hvActions-filters {
  display: -webkit-flex;
  display: flex;
  padding: 0.75rem;
  padding-top: 0.5rem; }
  .hvActions-filters input {
    margin-right: 0.5rem; }
  .hvActions-filters .hvActions-filters--phone {
    margin-right: 2rem; }
  .hvActions-filters p {
    margin: 0 2rem 0 0;
    color: #28628F;
    font-weight: 500; }

.hvActions--lead {
  background: #c9d5defa;
  border: 2px solid #c9d5defa;
  padding: 0 0.75rem;
  font-weight: 600;
  color: black; }

.hvActions--other,
.hvActions--solicitation {
  border: 2px solid #c9d5defa;
  padding: 0 0.75rem;
  font-weight: 600;
  color: black; }

.hvActions--unclassified {
  padding-left: 2px;
  font-weight: 400; }

.session-info__count {
  font-size: 30px;
  color: #03405f; }

.session-info__header {
  padding-left: 10px;
  font-size: 1.25rem; }

.session-info__title {
  font-weight: 400;
  color: #6CB33F; }

.session-info__dates {
  color: #616668;
  font-weight: 100; }

.session-info__return {
  padding: 0.5rem;
  font-size: 0.875rem; }

.spinner {
    margin: auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
}

.spinner > div {
    background-color: #28628f;
    height: 100%;
    width: 6px;
    margin: 2px;
    display: inline-block;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.loading-white {
    width: 40px;
    height: 20px;
}
.loading-white > div {
    background-color: white;
    width: 4px;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
    }
}

@keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
    }
}

.thomas-pagination {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #A4ACB3;
  display: -webkit-flex;
  display: flex;
  color: #28628F; }
  @media (max-width: 1047.98px) {
    .thomas-pagination {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; } }

.pagination-item {
  display: -webkit-flex;
  display: flex;
  height: 36px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer; }

.pagination-item__num {
  width: 32px;
  border-right: 1px solid #A4ACB3; }
  .pagination-item__num.active {
    background-color: #28628F;
    color: #fff; }

.pagination-item__prev {
  padding: 0 0.5rem;
  border-right: 1px solid #A4ACB3; }
  .pagination-item__prev.active {
    color: #A4ACB3;
    cursor: default; }

.pagination-item__next {
  padding: 0 0.5rem; }
  .pagination-item__next.active {
    color: #A4ACB3;
    cursor: default; }

.pagination-index__list {
  -webkit-flex: 3 1;
          flex: 3 1; }
  @media (max-width: 1047.98px) {
    .pagination-index__list {
      display: none; } }

.pagination-index__dropdown {
  display: none; }
  @media (max-width: 1047.98px) {
    .pagination-index__dropdown {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 1 1;
              flex: 1 1; } }

.pagination-index__dropdown-custom {
  font-size: medium;
  -webkit-flex: 1 1;
          flex: 1 1; }

.pagination-index__key {
  border-right: 1px solid #A4ACB3;
  -webkit-flex: 1 1;
          flex: 1 1;
  white-space: nowrap;
  padding: 2px; }
  .pagination-index__key:last-child {
    border-right: 0px; }
  .pagination-index__key.active {
    background-color: #28628F;
    color: #fff; }

.pagination-index__search-bar {
  -webkit-flex: 1 1;
          flex: 1 1; }
  @media (max-width: 1047.98px) {
    .pagination-index__search-bar {
      -webkit-flex: 1 1;
              flex: 1 1; } }

body {
  background-color: unset !important; }

.home-cards {
  display: -ms-grid;
  grid-template-columns: 325px 1fr 1fr !important;
  grid-gap: 2%;
  grid-row-gap: 1rem !important; }
  .home-cards .top-countcard {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .home-cards .session-depth {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }
  .home-cards .session-length {
    -ms-grid-column: 5;
    -ms-grid-row: 1; }
  @media (max-width: 1047.98px) {
    .home-cards {
      grid-template-columns: 1fr !important;
      grid-template-rows: 1fr 1fr 1fr !important; } }

.hvActions {
  -ms-grid-columns: 230px 145px 60px 3fr 3fr 4fr;
  -ms-grid-rows: 50px;
  grid-template-columns: 230px 145px 60px 3fr 3fr 4fr; }
  .hvActions div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .hvActions div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .hvActions div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }
  .hvActions div:nth-child(4) {
    -ms-grid-column: 4;
    -ms-grid-row: 1; }
  .hvActions div:nth-child(5) {
    -ms-grid-column: 5;
    -ms-grid-row: 1; }
  .hvActions div:nth-child(6) {
    -ms-grid-column: 6;
    -ms-grid-row: 1; }
  @media (max-height: 46em) and (min-width: 65em) {
    .hvActions {
      grid-template-columns: 200px 125px 50px 3fr 3fr 4fr; } }
  @media (max-width: 1047.98px) {
    .hvActions {
      grid-template-columns: 200px 125px 50px 3fr 3fr 4fr; } }
  .hvActions > * {
    -webkit-justify-content: start;
            justify-content: start; }
  .hvActions .thomas-table__timestamp {
    color: #28628F; }
    .hvActions .thomas-table__timestamp:hover {
      background-color: #dfe6ec;
      cursor: pointer; }
  .hvActions .thomas-table__location div {
    padding-left: 0.5rem; }

.row-open {
  -ms-grid-rows: 50px 1fr; }

.hvAction-icon {
  margin-right: 0.5rem; }

.thomas-table__actionType {
  -webkit-justify-content: center;
          justify-content: center; }

.wt-logo {
  width: 23em !important; }
  @media (max-width: 1047.98px) {
    .wt-logo {
      width: 14em !important; } }

.nav-active .wt-logo {
  width: 15em !important; }

.section-header--with-export {
  display: -webkit-flex;
  display: flex; }
  .section-header--with-export .results-control {
    -webkit-flex: 1 1;
            flex: 1 1; }

/* ----------------------------
    Dropdown Overrides
---------------------------- */
.rw-widget > .rw-widget-container {
  border-radius: 0; }

.rw-input,
.rw-placeholder,
.rw-list-option,
.rw-state-focus {
  color: #28628F !important; }

.rw-multiselect-tag {
  margin: 4px; }

.rw-list-option {
  padding: 0.25rem;
  padding-left: 12px;
  line-height: 1.25em;
  font-size: 15px; }

.filter-target .rw-list-option {
  padding: 0;
  margin: 0; }

.rw-list-option.rw-state-selected {
  background-color: #67c9fa;
  border-color: #67c9fa; }

.rw-list-option.rw-state-selected:hover {
  background-color: #a6d7f0;
  border-color: #a6d7f0; }

.rw-i-caret-down:before {
  content: url(/img/ico-select.svg);
  position: absolute;
  top: 0.5rem; }

.rw-multiselect .rw-btn-select:before {
  content: url(/img/ico-select.svg);
  position: absolute;
  top: 0.25rem; }

.rw-multiselect .rw-i-caret-down:before {
  content: unset; }

.rw-multiselect-tag-btn:before {
  content: unset;
  display: none; }

.rw-state-disabled,
.rw-state-disabled:hover {
  background: #f3f6f9 !important;
  background-color: #f3f6f9 !important; }

.rw-list {
  max-height: 400px; }

.rw-list-optgroup {
  padding-left: 3px;
  padding-top: 3px;
  background-color: #ecf1f5;
  font-size: 17px; }

.rw-subheader {
  color: black;
  padding: 0.25rem;
  font-weight: bold;
  display: block;
  background-color: white !important;
  cursor: text;
  line-height: 1.75em;
  font-size: 15px; }

.rw-subheader-item {
  padding: 0.25rem;
  padding-left: 12px;
  line-height: 1.15em;
  font-size: 15px;
  display: -webkit-flex;
  display: flex; }

.results-filter--content {
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%; }
  @media (max-width: 767.98px) {
    .results-filter--content {
      padding-left: 25px; } }

.results-filter--item {
  margin: 0 0.5rem 0 0;
  position: relative; }

.filterby--label {
  position: relative;
  color: #28628F;
  cursor: pointer; }

.results-filter--show-filters_button {
  display: none;
  cursor: pointer; }
  @media (max-width: 1047.98px) {
    .results-filter--show-filters_button {
      display: block;
      position: absolute;
      left: 5.5rem;
      top: -1rem;
      font-size: 36px;
      color: #6CB33F; } }
  @media (max-width: 767.98px) {
    .results-filter--show-filters_button {
      left: -1.5rem; } }
  .results-filter--show-filters_button.active {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    top: -0.5rem; }

.results-filter--content-filters {
  margin-bottom: 0.5rem;
  display: -webkit-flex;
  display: flex; }
  .results-filter--content-filters > * {
    -webkit-flex: 3 1;
            flex: 3 1; }
  .results-filter--content-filters :last-child {
    -webkit-flex: 1 1;
            flex: 1 1; }
  @media (max-width: 1047.98px) {
    .results-filter--content-filters {
      display: none; }
      .results-filter--content-filters.active {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 1fr 1fr; } }
  @media (max-width: 767.98px) {
    .results-filter--content-filters.active {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(5, 1fr); } }

.results-filter--index-pagination-filters {
  margin-bottom: 0.5rem;
  display: -webkit-flex;
  display: flex; }

@media (max-width: 1047.98px) {
  .filter-referer {
    grid-area: 1 / 1 / 1 / 3; }
  .filter-target {
    grid-area: 1 / 3 / 1 / 5; }
  .filter-action {
    grid-area: 1 / 5 / 1 / 7; }
  .filter-search {
    margin-top: 1rem;
    grid-area: 2 / 1 / 2 / 6; }
  .filter-apply {
    margin-top: 1rem; } }

@media (max-width: 767.98px) {
  .filter-referer,
  .filter-target,
  .filter-action,
  .filter-search,
  .filter-apply {
    width: 100%;
    grid-area: unset; } }

.results-filter__options_search-label {
  color: #616668;
  font-size: 12px;
  position: absolute;
  top: -20px;
  white-space: nowrap; }

.smaller-item {
  font-size: 0.875rem; }

.results-filter__active_items {
  display: block; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .results-filter__active_items {
      display: block; } }

.results-filter__category {
  padding-right: 2rem; }

.results-filter__category-title {
  font-size: 0.875rem;
  margin: 0.5rem 0 0.5rem 0; }
  .results-filter__category-title span {
    cursor: pointer;
    font-weight: 500;
    color: #3CB4E7; }
  .results-filter__category-title hr {
    border: 0;
    height: 1px;
    border-bottom: 0.5px dotted grey; }

.results-filter__category-title-bold {
  font-weight: 800; }

.results-filter__active-filters {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .results-filter__active-filters {
      width: 100%; } }
  .results-filter__active-filters > * {
    margin: 0 0.5rem 0.5rem 0; }

.results-filter__active-item {
  padding: 0.25rem 0;
  color: #3CB4E7;
  display: -webkit-flex;
  display: flex; }

.results-filter__active-item__label {
  margin-right: 0.5rem;
  display: -webkit-flex;
  display: flex;
  font-weight: bold; }

.results-filter__active-item-container {
  display: -webkit-flex;
  display: flex; }
  .results-filter__active-item-container > span {
    margin: 0 0 0 0.5rem; }

.results-filter__active-item-append {
  margin: 0 0.5rem 0 0; }

.results-filter__active_close {
  padding-left: 10px;
  font-weight: bold;
  cursor: pointer; }

.rw-input {
  padding: 0 0.5rem; }

.active-item {
  padding: 0;
  color: #28628F;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0.5rem; }
  .active-item svg {
    margin: 0 0.5rem; }

.company-info {
  display: -ms-grid;
  -ms-grid-columns: 180px 360px 1fr;
  border-bottom: 2px dotted #dfe6ec;
  padding: 1rem 0.5rem;
  padding-bottom: 1rem;
  display: grid;
  grid-template-columns: 180px 360px auto; }
  .company-info .company-info__logo {
    -ms-grid-column: 1; }
  .company-info .company-info__name {
    -ms-grid-column: 2; }
  .company-info .company-info__description {
    -ms-grid-column: 3; }
  @media (max-width: 767.98px) {
    .company-info {
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto; } }

.company-info--logo-unavailable {
  display: -ms-grid;
  -ms-grid-columns: 0px 360px auto;
  border-bottom: 2px dotted #dfe6ec;
  padding: 1rem 0.5rem;
  padding-bottom: 1rem;
  display: grid;
  grid-template-columns: 0px 360px auto; }
  .company-info--logo-unavailable .company-info__logo {
    -ms-grid-column: 1; }
  .company-info--logo-unavailable .company-info__name {
    -ms-grid-column: 2; }
  .company-info--logo-unavailable .company-info__description {
    -ms-grid-column: 3; }
  @media (max-width: 767.98px) {
    .company-info--logo-unavailable {
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto; } }

.company-info--logo-unavailable {
  display: grid;
  grid-template-columns: 0px 360px auto;
  border-bottom: 2px dotted #dfe6ec;
  padding: 1rem 0.5rem;
  padding-bottom: 1rem; }
  @media (max-width: 767.98px) {
    .company-info--logo-unavailable {
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto; } }

.company-info__logo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  @media (max-width: 767.98px) {
    .company-info__logo {
      padding-bottom: 0.5rem; } }
  .company-info__logo img {
    padding: 0 0.5rem;
    max-width: 100%; }

.company-info__logo--display-none {
  display: none; }

.company-info__name {
  padding: 0 0.5rem 0 1.5rem; }

@media (max-width: 767.98px) {
  .company-info__title-box {
    text-align: center; } }

.company-info__details-box {
  margin-top: 0.5rem;
  border-top: 1px solid #dfe6ec; }

.company-info__details-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
          flex-flow: row; }

.company-info__details-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end; }

.company-info__details-item {
  font-size: 0.875rem;
  padding: 5px;
  border-bottom: 1px solid #dfe6ec; }
  .company-info__details-item *:last-child {
    padding-left: 5px;
    display: inline-block; }

.company-info__description {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 3rem 1.5rem 1rem 1rem;
  padding-left: 1.5rem;
  border-left: #dfe6ec 1px solid; }
  @media (max-width: 1047.98px) {
    .company-info__description {
      grid-area: 2 / 1 / 2 / 4;
      margin: 1rem 0.5rem;
      border: none;
      padding: 0; } }
  @media (max-width: 767.98px) {
    .company-info__description {
      grid-area: unset; } }

.session-detail-row {
  padding: 0.75rem; }

.row-content__borderless {
  display: -webkit-flex;
  display: flex; }

.row-content {
  display: -webkit-flex;
  display: flex;
  border-bottom: 0.5px dotted grey;
  padding-bottom: 10px; }

.results-control {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0.5rem; }

.results-control__display-amount {
  color: #28628F;
  font-size: 0.875rem;
  padding: 0.5rem 1rem 0.5rem 0; }
  .results-control__display-amount span {
    cursor: pointer;
    font-weight: bold; }
    .results-control__display-amount span.active {
      color: #6CB33F; }

.results-control__view-type {
  color: #28628F;
  font-size: 0.875rem;
  padding: 0.5rem 1rem 0.5rem 1rem; }
  .results-control__view-type span {
    cursor: pointer; }
    .results-control__view-type span.active {
      border-bottom: 1px #6CB33F solid;
      font-weight: bold; }

.results-control__utility-icons {
  display: -webkit-flex;
  display: flex;
  font-size: 0.875rem;
  padding: 0.5rem 0 0.5rem 0.5rem;
  margin-left: auto; }
  .results-control__utility-icons * {
    padding-left: 0.5rem; }
  .results-control__utility-icons .spinner > div {
    padding-left: 0.1rem;
    width: 3px; }
  .results-control__utility-icons .spinner {
    display: inline;
    height: 20px; }

.flex-col {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.details-btn {
  background-color: #ecf1f5;
  border: #dfe6ec 1px solid;
  padding: 0px 0.5rem;
  color: #28628F;
  font-weight: bold;
  width: 65px;
  height: 25px; }

.section-footer {
  margin-top: 0.5rem; }

.section-header__secondary {
  float: left;
  margin: 0.5rem 0.5rem 0 0; }

.results-timeline--header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 0; }

.results-timeline--date {
  color: #28628F;
  padding: 0.5rem 0; }

.results-timeline__row {
  border-left: 2px solid #6CB33F;
  margin-left: 0.5rem;
  padding: 0.5rem 0 0.5rem 1rem; }

.results-timeline__card-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: bold;
  font-size: 1.25rem;
  margin: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: #ecf1f5 1px solid;
  color: #28628F; }
  .results-timeline__card-header .timeline--location {
    font-size: 1.0625rem;
    font-weight: 500; }
  .results-timeline__card-header small {
    color: #616668;
    font-size: 0.875rem;
    font-weight: 200;
    margin-left: 0.5rem; }

.results-timeline__card-title {
  margin: 0 1rem 0 0.5rem; }

.results-timeline__card--details-show {
  cursor: pointer;
  margin-left: auto;
  padding: 0.25rem; }
  .results-timeline__card--details-show span {
    font-weight: 400;
    font-size: 1.0625rem;
    color: #28628F; }
  .results-timeline__card--details-show:hover {
    background-color: #dfe6ec;
    cursor: pointer; }

.results-timeline__card-plus {
  position: absolute;
  font-size: 32px;
  color: #6CB33F;
  left: -0.5px;
  top: -2px;
  cursor: pointer; }
  .results-timeline__card-plus.active {
    top: -4px;
    left: 2px; }

.results-timeline__card-date {
  font-size: 1.25rem;
  margin: 0 0.5rem;
  color: #212529; }

.date-icon {
  margin-left: 0.5rem;
  margin-right: 0 !important; }

.results-timeline__card-details {
  display: -ms-grid;
  -ms-grid-columns: 15% 20% 20% 25% 20%;
  display: grid;
  grid-template: 1fr / 15% 20% 20% 25% 20%;
  margin: 0.5rem 1rem; }
  .results-timeline__card-details div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .results-timeline__card-details div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .results-timeline__card-details div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }
  .results-timeline__card-details div:nth-child(4) {
    -ms-grid-column: 4;
    -ms-grid-row: 1; }
  .results-timeline__card-details div:nth-child(5) {
    -ms-grid-column: 5;
    -ms-grid-row: 1; }
  @media (max-width: 767.98px) {
    .results-timeline__card-details {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; } }

.results-timeline__card-stat {
  display: block;
  font-size: 1.0625rem; }
  .results-timeline__card-stat :first-child {
    font-weight: 200; }
  @media (max-width: 767.98px) {
    .results-timeline__card-stat {
      display: -webkit-flex;
      display: flex; } }

.referrer-link {
  font-weight: bold; }

.results-timeline__card-icons {
  font-size: 1.75rem; }
  .results-timeline__card-icons * {
    margin-right: 0.5rem; }
    @media (max-height: 46em) and (min-width: 65em) {
      .results-timeline__card-icons * {
        width: 20px; } }
    @media (max-height: 40em) and (min-width: 65em) {
      .results-timeline__card-icons * {
        width: 18px; } }

.results-timeline__card-icon {
  position: relative;
  top: 10px;
  left: -2rem;
  font-size: 1.75rem;
  color: #BC4239;
  background-color: #ecf1f5; }

.results-timeline__card-icon-2 {
  position: relative;
  top: 50px;
  left: -3.75rem;
  font-size: 1.75rem;
  color: #BC4239;
  background-color: #ecf1f5; }

.results-timeline__card-icon-n {
  position: relative;
  top: 10px;
  left: -23px; }
  @media (max-height: 46em) and (min-width: 65em) {
    .results-timeline__card-icon-n {
      width: 9px;
      left: -19px; } }
  @media (max-height: 40em) and (min-width: 65em) {
    .results-timeline__card-icon-n {
      width: 8px;
      left: -17px; } }

.empty-search-results {
  color: #28628F;
  margin: 0.5rem;
  padding: 0.5rem;
  font-size: 1.25rem; }
  .empty-search-results span {
    text-decoration: underline;
    cursor: pointer; }

.dotter {
    width: 70px;
    text-align: start;
    padding: 0.5rem 0;
}

.dotter > div {
    width: 10px;
    height: 10px;
    background-color: #28628f;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.dotter .dot1 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.dotter .dot2 {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.dotter .dot2 {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.session-detail__header {
  border-bottom: none;
  margin-bottom: 0;
  border-top: #ecf1f5 1px solid;
  padding-top: 0.5rem;
  margin-left: 1rem;
  color: black; }

.session-detail__table {
  width: calc(100% - 2rem);
  margin: 1rem;
  table-layout: fixed; }
  @media (max-width: 767.98px) {
    .session-detail__table {
      width: auto; } }

.session-detail__table {
  border: 1px solid #A4ACB3;
  margin-top: 0; }

.session-detail__table th {
  background-color: #fff;
  color: black;
  border: 1px solid #A4ACB3;
  font-weight: bolder !important; }

.session-detail__table td {
  border-right: 1px solid #A4ACB3;
  border-left: 1px solid #A4ACB3; }

.session-detail__table tbody tr:nth-child(odd) {
  background-color: #ecf1f5; }

.session-detail__table tbody tr:nth-child(even) {
  background-color: #fff; }

.session-detail__table-id {
  font-weight: bold; }

.hv-page-icon {
  float: left;
  margin-right: 0.5rem !important; }

.font-hv-page {
  font-size: 1.75rem; }

.session-detail__table-pageUrl {
  color: #28628F; }
  .session-detail__table-pageUrl div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.0625rem;
    margin: auto; }

.session-detail__table-where * {
  margin-right: 0.5rem; }

.where-icon {
  color: #28628F;
  font-size: 1.75rem; }

@media (max-width: 1047.98px) {
  .session-detail__table td {
    padding: 0.5rem; } }

.results-timeline__card-loader {
  display: block !important;
  padding: 8px 18px 16px; }

.timeline-item {
  background: #fff;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 3px;
  padding: 12px;
  margin: 0 auto; }

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.animated-background {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative; }

.background-masker {
  background: #fff;
  position: absolute; }

/* Every thing below this is just positioning */
.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  left: 0px;
  right: 0;
  height: 10px; }

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 0px;
  height: 8px;
  width: 0px; }

.background-masker.header-bottom {
  top: 18px;
  height: 6px; }

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 6px; }

.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 300px;
  right: 0; }

.background-masker.subheader-right {
  left: 230px; }

.background-masker.subheader-bottom {
  top: 30px;
  height: 10px; }

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 6px; }

.background-masker.content-top {
  height: 20px; }

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 60px;
  height: 8px; }

.background-masker.content-second-line {
  top: 68px; }

.background-masker.content-second-end {
  left: 420px;
  top: 74px; }

.background-masker.content-third-line {
  top: 82px; }

.background-masker.content-third-end {
  left: 300px;
  top: 88px; }

.table td {
  vertical-align: middle; }

.results-table__visitorName {
  color: #28628F;
  font-size: 1.25rem; }
  .results-table__visitorName small {
    color: #616668;
    font-size: 0.875rem;
    font-weight: 200;
    margin-left: 0.5rem; }

.results-table__visitDate,
.results-table__visitTime {
  text-align: center; }

.results-table__table {
  border: 1px solid #A4ACB3; }

.results-table__table th {
  background-color: #dfe6ec;
  color: black;
  border: 1px solid #A4ACB3;
  font-weight: bolder !important; }

.results-table__table tr:nth-child(odd) {
  background-color: #fff; }

.results-table__table tr:nth-child(even) {
  background-color: #ecf1f5; }

.results-table__sortable {
  text-decoration: underline;
  color: #28628F;
  cursor: pointer; }

.results-table__table td {
  border-right: 1px solid #A4ACB3;
  border-left: 1px solid #A4ACB3; }

@media (max-width: 1047.98px) {
  .results-table__table td {
    padding: 0.5rem; }
  .results-table__pagesInSession,
  .results-table__sessionDuration,
  .results-table__visitTime {
    width: 10%; }
  .results-table__visitDate {
    width: 15%; } }

.thomas-row-clickable:hover {
  background-color: #dfe6ec !important;
  cursor: pointer; }

.empty-high-value-actions {
  background-color: #dfe6ec;
  border: 1px solid #A4ACB3;
  font-size: 1.25rem;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem; }
  .empty-high-value-actions div {
    margin: 0.5rem; }
    .empty-high-value-actions div img {
      margin-right: 0.5rem;
      margin-left: 2rem; }

.hVActions-header {
  font-weight: 600;
  padding: 1rem; }

.site-content__primary .tet-class iframe {
  margin-top: -3rem !important; }

.toggleswitch-box {
  border: 1px #dfe6ec solid;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 0.5rem;
  background-color: #fff; }

.toggleswitch-title {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  color: #212529; }

.thomas-toggleswitch {
  z-index: 10;
  position: relative;
  width: 150px;
  height: 40px;
  margin: 0.5rem; }

.thomas-toggleswitch-white-l {
  position: absolute;
  left: -5px;
  z-index: 10;
  width: 35px;
  height: 40px;
  background-color: white; }

.thomas-toggleswitch-white-r {
  position: absolute;
  left: 110px;
  z-index: 10;
  width: 35px;
  height: 40px;
  background-color: white; }

.toggleswitch-background {
  z-index: 2;
  position: absolute;
  left: 30px;
  width: 80px;
  border-radius: 34px;
  height: 40px;
  background-color: #dfe6ec;
  border: 1px solid #dfe6ec;
  transition: 0.25s; }
  .toggleswitch-background.active {
    border: 1px solid #6CB33F; }

.toggleswitch-button {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  height: 40px;
  width: 40px;
  left: 30px;
  background-color: #6CB33F;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: 0.25s; }
  .toggleswitch-button.active {
    -webkit-transform: translateX(40px);
            transform: translateX(40px); }

.toggleswitch-yes {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  color: #212529;
  left: -1px;
  top: 8px;
  font-weight: bold;
  transition: 0.25s; }
  .toggleswitch-yes.active {
    -webkit-transform: translateX(40px);
            transform: translateX(40px); }

.toggleswitch-no {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  color: #212529;
  left: 78px;
  top: 8px;
  font-weight: bold;
  transition: 0.25s; }
  .toggleswitch-no.active {
    -webkit-transform: translateX(40px);
            transform: translateX(40px); }

.data-integration {
  margin: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dotted #A4ACB3; }

.data-integration__subheader {
  margin-bottom: 0.5rem; }

.data-integration__subheader-info {
  font-size: 1.0625rem;
  color: #616668;
  font-style: italic;
  font-weight: 100;
  margin-bottom: 1rem; }

.thomas-textbox {
  margin-bottom: 0.5rem; }

.thomas-textbox__label {
  color: #616668;
  display: -webkit-flex;
  display: flex; }

.thomas-textbox__sublabel {
  color: #616668;
  font-weight: 100;
  font-style: italic;
  padding-left: 0.5rem; }

.textbox-adder {
  display: grid;
  grid-template-columns: 70%;
  position: relative;
  margin-bottom: 2rem; }
  @media (max-width: 1047.98px) {
    .textbox-adder {
      grid-template-columns: 95%; } }

.textbox-adder_box {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .textbox-adder_box {
      width: 75%; } }

.textbox-adder_textbox {
  width: 100%;
  margin: 0.5rem 0; }

.textbox-adder_add {
  font-size: 2.5rem;
  color: #6CB33F;
  position: absolute;
  bottom: 0px;
  left: calc(69% + 20px);
  cursor: pointer; }
  @media (max-width: 1047.98px) {
    .textbox-adder_add {
      left: unset;
      right: 5px; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .textbox-adder_add {
      left: calc(75% + 20px); } }

.textbox-adder_remove {
  font-size: 1.5rem;
  font-weight: bold;
  color: #28628F;
  position: absolute;
  bottom: 0.75rem;
  right: 1rem;
  cursor: pointer; }

.basic-settings {
  margin: 1rem;
  border-bottom: 1px dotted #A4ACB3; }

.basic-settings__half-row {
  display: -ms-grid;
  -ms-grid-columns: 37% 37% 1fr;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 35% 35% auto; }
  .basic-settings__half-row .thomas-textbox {
    -ms-grid-column: 1; }
  .basic-settings__half-row .basic-settings--timezone {
    -ms-grid-column: 2; }
  @media (max-width: 1047.98px) {
    .basic-settings__half-row {
      grid-template-columns: 47.5% 47.5%; } }

.close-box {
  padding-right: 2rem; }

.basic-settings__subheader-info {
  font-size: 1.0625rem;
  color: #616668;
  font-weight: 100;
  margin-bottom: 1rem; }

.advanced-settings {
  margin: 1rem;
  border-bottom: 1px dotted #A4ACB3; }

.advanced-settings--row {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 45% 55%;
  position: relative; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .advanced-settings--row {
      display: block;
      width: 50%; } }

.advanced-settings--textbox {
  position: relative; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .advanced-settings--textbox {
      display: block;
      width: 50%; } }

.advanced-settings--add {
  font-size: 2.5rem;
  color: #6cb33f;
  position: absolute;
  top: 0.75rem;
  left: calc(45% - 1.75rem);
  cursor: pointer; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .advanced-settings--add {
      left: calc(45% + 2.5rem); } }

.advanced-settings--active-items {
  margin: 1.5rem 0 0 1.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .advanced-settings--active-items > * {
    margin: 0 0.5rem 0.5rem 0.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 0.5rem; }

.save-settings {
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  height: 100px;
  bottom: 0;
  width: 100%;
  margin-top: 3rem; }

.save-settings--button {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.webtraxs-tutorials {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 1.5rem;
  grid-gap: 2rem; }
  @media (max-width: 1047.98px) {
    .webtraxs-tutorials {
      grid-template-columns: auto auto; } }
  @media (max-width: 767.98px) {
    .webtraxs-tutorials {
      grid-template-columns: auto; } }

.settings-calltracking {
  padding: 1.5rem; }

.card--header {
  font-size: 1.75rem;
  font-weight: 500; }

.card--content {
  font-size: 1.25rem;
  font-weight: 200;
  margin: 0.5rem 0; }
  .card--content b {
    font-weight: 600; }

.card--button {
  margin: 0.5rem 0; }

.card--codeblock {
  background-color: #ecf1f5;
  font-size: 1rem;
  padding: 1rem;
  margin: 1rem;
  word-wrap: break-word;
  position: relative; }

.green-checkmark {
  float: left;
  width: 30px;
  height: 30px;
  background: #6CB33F;
  border-radius: 30px;
  margin-right: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .green-checkmark svg {
    color: #fff;
    padding: 3px; }

.visitor-demo-settings {
  margin: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dotted #A4ACB3; }

.visitor-demo-settings__subheader {
  font-size: 1.0625rem;
  font-weight: 400;
  margin-bottom: 1.5rem; }
  .visitor-demo-settings__subheader img {
    width: 18px;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem; }

.visitor-demo-settings__categories {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, 350px);
  margin: 0.5rem 0; }
  @media (max-width: 1047.98px) {
    .visitor-demo-settings__categories {
      grid-template-columns: 1fr 1fr; } }
  @media (max-width: 767.98px) {
    .visitor-demo-settings__categories {
      grid-template-columns: 1fr; } }

.target-audience-icon {
  font-size: 1.25rem;
  color: #BC4239; }

.visitor-demo-settings__row {
  display: grid;
  grid-template-columns: 40% auto;
  padding: 0.75rem 0 1.5rem 0;
  border-top: 1px dotted #dfe6ec; }
  @media (max-width: 1047.98px) {
    .visitor-demo-settings__row {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr; } }

.visitor-demo--active-item {
  color: #28628F;
  font-weight: 600;
  font-size: 0.875rem;
  padding-left: 0 !important;
  margin-bottom: 0.5rem; }
  .visitor-demo--active-item > * {
    display: -webkit-flex;
    display: flex;
    padding: 0.25rem;
    -webkit-align-items: center;
            align-items: center; }
  .visitor-demo--active-item span {
    margin-left: 0.25rem; }
  .visitor-demo--active-item svg {
    font-size: 1.25rem; }

.vd--label {
  font-weight: 500; }

.high-value-page-settings {
  margin: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dotted #A4ACB3; }

.high-value-page-settings__subheader {
  font-size: 1.0625rem;
  font-weight: 400;
  margin-bottom: 1.5rem; }

.high-value-page-settings__categories {
  margin: 0.5rem 0; }

.hvp-textbox {
  margin-left: 0.5rem; }

.empty-visitor-card {
  margin: 1rem; }

.visitor-locations {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
  padding: 1rem; }

.international-visitors {
  margin-top: 1rem; }

.map-display {
  height: 350px; }

.mapboxgl-canvas {
  position: unset !important;
  height: 300px; }

.map-item {
  position: relative;
  color: white;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 20px;
  height: 20px;
  top: -10px;
  left: -10px;
  font-size: 0.875rem; }

.map-item__cluster {
  background-color: #BC4239;
  box-shadow: 0 0 0 4px #f1afaf;
  width: 16px;
  height: 16px;
  top: -8px;
  left: -8px; }

.card-group {
  display: -ms-grid;
  -ms-grid-columns: 48% 2% 50%;
  display: grid;
  grid-template-columns: 48% 50%;
  grid-gap: 2%;
  grid-row-gap: 1.5rem; }
  @media (max-width: 1047.98px) {
    .card-group {
      grid-template-columns: 1fr; } }
  @media (max-width: 767.98px) {
    .card-group {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; } }

.card-group--left {
  margin: 2rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-grid-column: 1; }
  @media (max-width: 1047.98px) {
    .card-group--left {
      margin-bottom: 0; } }
  @media (max-width: 767.98px) {
    .card-group--left {
      height: unset; } }

.card-group--right {
  margin: 2rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-grid-column: 3; }
  @media (max-width: 1047.98px) {
    .card-group--right {
      margin: 0; } }
  @media (max-width: 767.98px) {
    .card-group--right {
      height: unset; } }

.visitor-pins-disclaimer {
  margin: 0.25rem 0;
  font-style: italic;
  font-size: 1.0625rem;
  color: #616668;
  font-weight: 300; }

.audience-buttons {
  margin-bottom: 2.5rem;
  display: -webkit-flex;
  display: flex; }
  .audience-buttons * {
    margin-right: 1rem; }
  .audience-buttons div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #28628F;
    font-size: 1.25rem;
    text-decoration: underline; }

.thomas-table__url {
  color: #28628F;
  font-size: 1.0625rem;
  font-weight: 500;
  min-width: 0; }

.thomas-table__companyIndustry,
.thomas-table__companyAnnualSales,
.thomas-table__city {
  text-align: center; }

.analytics-table {
  line-height: 2.5; }
  .analytics-table > *:first-child {
    -webkit-justify-content: start;
            justify-content: start; }

.analytics-referrers {
  line-height: 1.5;
  grid-template-columns: 20% 10% 14% 11% 13% 10% 12% 10%; }

.analytics-sessionduration-single {
  grid-template-columns: 22% 23% 14% 11% 10% 8% 12%; }

.analytics-referrer {
  grid-template-columns: 40% 19% 12% 12% 9% 8%; }

.analytics-exitpages {
  grid-template-columns: 85% 15%; }
  .analytics-exitpages div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .analytics-exitpages div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }

.analytics-toppages,
.analytics-searchterms,
.analytics-browsers {
  grid-template-columns: 70% 20% 10%; }
  .analytics-toppages div:nth-child(1),
  .analytics-searchterms div:nth-child(1),
  .analytics-browsers div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .analytics-toppages div:nth-child(2),
  .analytics-searchterms div:nth-child(2),
  .analytics-browsers div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .analytics-toppages div:nth-child(3),
  .analytics-searchterms div:nth-child(3),
  .analytics-browsers div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }

.analytics-entrypages {
  grid-template-columns: 62% 12% 12% 14%; }
  .analytics-entrypages div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .analytics-entrypages div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .analytics-entrypages div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }
  .analytics-entrypages div:nth-child(4) {
    -ms-grid-column: 4;
    -ms-grid-row: 1; }

.accounts-selection {
  grid-template-columns: 30% 30% 40%; }
  .accounts-selection div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .accounts-selection div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .accounts-selection div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }

.thomas-table__pageUrl,
.thomas-table__visitorName,
.thomas-table__pagesCount,
.thomas-table__length,
.thomas-table__dateTime,
.thomas-table__openDetails {
  color: #28628F;
  font-weight: 500; }

.thomas-table__durationRange,
.thomas-table__depth {
  text-decoration: underline; }

.thomas-table__funnelAnalysis:hover,
.thomas-table__dateTime:hover,
.thomas-table__openDetails:hover {
  background-color: #dfe6ec;
  cursor: pointer; }

.analytics-control {
  margin-top: 1rem; }

.analytics-home-cards {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, auto);
  grid-gap: 1.5rem;
  grid-row-gap: 1rem; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .analytics-home-cards {
      display: -ms-grid;
      -ms-grid-columns: 1fr 1fr;
      -ms-grid-rows: auto auto auto auto; }
      .analytics-home-cards .card:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(2) {
        -ms-grid-column: 2;
        -ms-grid-row: 1;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(4) {
        -ms-grid-column: 2;
        -ms-grid-row: 2;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(6) {
        -ms-grid-column: 2;
        -ms-grid-row: 3;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
        margin: 0.5rem; }
      .analytics-home-cards .card:nth-child(8) {
        -ms-grid-column: 2;
        -ms-grid-row: 4;
        margin: 0.5rem; } }
  @media (max-width: 1047.98px) {
    .analytics-home-cards {
      grid-template-columns: 1fr; } }

.analytics-sessions-cards {
  margin-top: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  grid-row-gap: 1rem; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .analytics-sessions-cards {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .analytics-sessions-cards .session-depth:first-child {
        margin-right: 2rem; } }
  @media (max-width: 1047.98px) {
    .analytics-sessions-cards {
      grid-template-columns: 1fr; } }

.top-countcard {
  padding: 1rem;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 100; }

.top-countcard--list {
  padding-left: 0; }

.top-countcard--row {
  display: -webkit-flex;
  display: flex;
  position: relative; }

.top-countcard--order {
  color: #616668;
  padding-right: 0.5rem;
  width: 38px; }

.top-countcard--order-color {
  width: 1rem;
  height: 1rem;
  margin: 0.25rem;
  margin-top: 0.5rem; }

.top-countcard--ref {
  color: #188dbf;
  padding-right: 0.5rem;
  position: absolute;
  background: white;
  z-index: 2; }

.top-countcard--fill {
  color: #A4ACB3;
  position: absolute;
  top: 15px;
  z-index: 1;
  border-top: 1px solid #dfe6ec;
  width: calc(100% - 56px); }

.top-countcard--total {
  font-weight: bold;
  padding-left: 0.5rem;
  position: absolute;
  right: 0.25rem;
  background: white;
  z-index: 2; }

.top-countcard--view-all {
  color: #28628F;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: auto; }
  .top-countcard--view-all :first-child {
    margin-left: 0.5rem; }

.topcountcard-piechart {
  float: left; }
  .topcountcard-piechart svg {
    margin: 0 !important;
    margin-right: 2rem; }

.search-term-show-more {
  color: #28628F;
  font-size: 1.0625rem;
  padding-top: 0.5rem;
  font-weight: 500;
  cursor: pointer; }

.topcountcard-loading .piechart-loading-donut {
  margin: 0;
  margin-right: 1rem;
  float: left; }

.topcountcard-loading .piechart-legend {
  height: 140px; }

.loading-2line-block {
  position: absolute;
  width: 100%; }

.top-countcard--column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative; }

@media (max-width: 767.98px) {
  .topcountcard-pie-desc {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; } }

.top-links {
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: 100; }

.top-links--row {
  display: -webkit-flex;
  display: flex;
  position: relative;
  margin-bottom: 0 !important; }
  .top-links--row:nth-child(odd) {
    background-color: #fff; }
  .top-links--row:nth-child(even) {
    background-color: #ecf1f5; }

.top-links--order {
  color: #616668;
  margin-left: 0.5rem;
  min-width: 32px; }

.top-links--url {
  color: #188dbf;
  font-size: 1.0625rem;
  padding-right: 4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.top-links--total {
  font-weight: bold;
  padding-left: 0.5rem;
  position: absolute;
  right: 0.5rem; }

.account-info {
  padding: 1.3rem; }
  .account-info .add-logo {
    background: #1b5170;
    padding: 12px;
    margin-bottom: 14px; }
    .account-info .add-logo p {
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
      padding: 0; }
  .account-info ul,
  .account-info h3,
  .account-info h4 {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 4px; }
  .account-info li {
    overflow: hidden;
    text-overflow: ellipsis; }

.welcome-screen {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: fixed;
  top: calc(50% - 24rem);
  left: calc(50% - 37.5rem);
  width: 75rem;
  height: 48rem;
  z-index: 10002;
  background-color: white;
  box-shadow: 0px 0px 10px 0px #616668;
  padding: 2rem; }
  @media (max-width: 1047.98px) {
    .welcome-screen {
      width: 40rem;
      left: calc(50% - 20rem);
      top: 2rem;
      height: 90%;
      overflow-y: scroll;
      text-align: center;
      padding: 2rem 1rem; } }
  @media (max-width: 767.98px) {
    .welcome-screen {
      width: 20rem;
      left: calc(50% - 10rem); } }

.welcome-screen-overlay {
  position: fixed;
  display: none;
  z-index: 10001;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  text-decoration: none; }
  .welcome-screen-overlay.active {
    display: block; }

.welcome-screen__header {
  -webkit-flex: 2 1;
          flex: 2 1; }
  .welcome-screen__header h1 {
    border-bottom: #dfe6ec 1px solid;
    padding-bottom: 1rem; }

.welcome-screen__body {
  -webkit-flex: 10 1;
          flex: 10 1; }

.welcome-screen__footer {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-top: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  @media (max-width: 1047.98px) {
    .welcome-screen__footer {
      position: relative;
      padding-top: 2rem;
      margin: 2rem; } }

.welcome-screen-terms-data {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media (max-width: 1047.98px) {
    .welcome-screen-terms-data {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center; } }

.data-block-1 {
  text-align: center;
  width: 20rem; }
  .data-block-1 img {
    width: 20rem; }

.data-block-2 {
  text-align: center; }
  .data-block-2 img {
    width: 23.5rem; }

.data-block-3 {
  text-align: center; }
  .data-block-3 img {
    width: 19rem; }

.db-block {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media (max-width: 767.98px) {
    .db-block {
      width: 100%;
      padding: 2rem 0; }
      .db-block img {
        width: 100%; } }

.welcome-screen-terms-data {
  margin: 2rem 0; }

.welcome-screen__steps {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end; }
  @media (max-width: 1047.98px) {
    .welcome-screen__steps {
      position: absolute;
      bottom: -2.5rem; } }

.ws-step {
  margin: 0 3px;
  border: 3px white solid;
  border-radius: 25px;
  background: #dfe6ec; }
  .ws-step.active {
    border: 3px solid #c8d6e2; }

.ws-step-ball {
  width: 20px;
  height: 20px;
  border: 3px white solid;
  border-radius: 20px; }

.data-block_header {
  font-size: 1.25rem; }

.terms-and-conditions {
  border: #ecf1f5 1px solid;
  height: 5rem;
  margin: 0;
  overflow-y: scroll; }
  .terms-and-conditions p {
    font-size: 0.75em; }

.tac-header {
  font-size: 1.1em;
  font-weight: 500; }

.tac-accepter {
  position: relative;
  padding-top: 0.25em; }
  .tac-accepter input {
    margin-right: 0.5rem; }
  .tac-accepter span {
    font-size: 1.1em;
    font-weight: 500; }

.tac-accept-notifier {
  position: absolute;
  left: 0;
  bottom: -1.25rem;
  color: #BC4239; }

.next-step-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 2rem 2rem 0; }
  @media (max-width: 1047.98px) {
    .next-step-btn {
      margin: 0;
      right: unset; } }

.skip-step-link {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 2rem 0.5rem 0;
  color: #28628F;
  cursor: pointer; }
  @media (max-width: 767.98px) {
    .skip-step-link {
      bottom: -4.5rem;
      right: unset; } }

.copy-tagging-code {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 2rem;
  color: #28628F;
  cursor: pointer; }

.clickable {
  cursor: pointer; }

.welcome-screen--target-audience {
  height: 27rem;
  overflow-y: scroll;
  background: #ecf1f5; }
  .welcome-screen--target-audience .visitor-demo-settings {
    background: white;
    padding-left: 1rem;
    border: none; }

.welcome-screen--high-value-pages {
  background: #ecf1f5;
  padding: 0.1em 0;
  max-height: 27rem;
  overflow-y: scroll; }
  .welcome-screen--high-value-pages .high-value-page-settings {
    background: white;
    padding-left: 1rem;
    border: none; }

.welcome-screen-technical-settings {
  background: #ecf1f5;
  padding: 0.1em 0;
  max-height: 27rem;
  overflow-y: scroll; }
  .welcome-screen-technical-settings .basic-settings {
    background: white;
    padding: 1rem;
    border: none; }

.font-size-40 {
  font-size: 40px; }

.noscroll {
  overflow: hidden; }

.webtrax-loading {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00334d;
  /* background-color: #ecf1f5; */ }
  .webtrax-loading .webtrax-loading-logo {
    width: 200px;
    position: absolute;
    left: 50%;
    margin-left: -168px;
    top: 30%;
    opacity: 0.2; }

.wt-loading-exit {
  opacity: 1; }

.wt-loading-exit-active {
  opacity: 0;
  transition: all 500ms ease-out; }
  .wt-loading-exit-active .webtrax-loading-logo {
    opacity: 0.2; }

.reg-container {
  padding-right: 1rem; }
  .reg-container .copyright-info {
    text-align: center; }

.registration-background-img {
  display: block;
  width: 30%;
  float: left;
  background-image: linear-gradient(to right, #00334d 0, #03405f 100%);
  background-repeat: repeat-x;
  background-position: center center;
  background-size: cover;
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  border-top: 3px solid #6CB33F; }

.bg-image-logo {
  position: absolute;
  max-width: 15em;
  text-align: center;
  top: 0.5rem; }

.bg-image-text {
  color: #fff;
  position: absolute;
  bottom: 0;
  padding: 3rem 2rem 3rem 2rem;
  max-width: 91.25rem;
  margin: 0 auto; }

.registration-page {
  width: 70%;
  float: right;
  background: white; }

.progress-steps {
  list-style: none;
  padding: 0 6rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  max-width: 740px;
  margin: auto;
  margin-bottom: 3rem; }

.progress-steps__step {
  font-size: 1.75rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 46px; }

.step-2:before {
  content: " ";
  height: 2px;
  width: 12rem;
  background-color: #A4ACB3;
  position: absolute;
  top: 1.25rem;
  left: 9.25rem; }

.step-3:before {
  content: " ";
  height: 2px;
  width: 12rem;
  background-color: #A4ACB3;
  position: absolute;
  top: 1.25rem;
  right: 9.25rem; }

.progress-steps__step__label {
  font-size: 1rem;
  font-weight: 500;
  color: #A4ACB3;
  position: absolute;
  top: 2.5rem; }
  .progress-steps__step__label.active {
    color: #03405f; }

.label-completed {
  color: #6CB33F; }

@media (max-width: 1047.98px) {
  .step-2:before {
    width: 11.5rem; }
  .step-3:before {
    width: 11.5rem; } }

@media (max-width: 767.98px) {
  .progress-steps {
    padding: 0 2rem; }
  .progress-steps__step__label {
    font-size: 0.75rem;
    width: 75px;
    text-align: center; }
  .progress-steps__step {
    font-size: 1.25rem;
    width: 36px; }
  .step-2:before {
    width: 4.75rem;
    top: 1rem;
    left: 4.5rem; }
  .step-3:before {
    width: 4.75rem;
    top: 1rem;
    right: 4.5rem; } }

.completed:before {
  background: #6CB33F; }

.progress-steps__step__icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  color: #A4ACB3;
  border: 2px #A4ACB3 solid;
  background-color: transparent;
  border-radius: 1.75rem;
  padding: 0.25rem; }

.active .progress-steps__step__icon {
  color: #fff;
  background: #03405f;
  border: 2px #03405f solid; }

.active::before {
  background: #03405f; }

.completed .progress-steps__step__icon {
  color: #fff;
  background: #6CB33F;
  border: 2px #6CB33F solid; }

.reg-page__header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  padding: 4rem 0 2rem 0; }

.form-webtrax--header {
  border-top: 0.5rem solid #dfe6ec;
  background-color: #ecf1f5;
  padding-top: 1rem;
  padding-left: 1rem; }

.form--webtrax-reg__url {
  border-radius: 0;
  background-color: #ecf1f5;
  padding: 1rem 2rem !important; }
  .form--webtrax-reg__url .actions {
    text-align: center; }
  .form--webtrax-reg__url p {
    text-align: center; }

.return-link {
  text-decoration: underline;
  cursor: pointer; }

.forgot-password-link {
  color: #28628F !important;
  text-decoration: underline !important;
  cursor: pointer; }

.flex-end-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.login-component {
  max-width: 30rem;
  margin: auto;
  height: 10rem; }
  .login-component .form-div {
    display: block; }
  .login-component button {
    float: right;
    margin-bottom: 1rem; }

.form--webtraxs-reg__error {
  height: 1.5rem;
  color: #BC4239; }

.form--error {
  border-color: #BC4239; }

.form--error:focus {
  box-shadow: 0 0 3px 1px #BC4239;
  border-color: #BC4239; }

.form-div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end; }

.url-btn-container {
  border: 1px solid #ecf1f5; }

.form--webtrax-reg__url-input {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-right: 1rem; }

fieldset {
  max-width: unset !important; }

.hs-form-field {
  padding: 0 0.25rem; }
  .hs-form-field label {
    width: unset !important;
    margin-top: 1rem; }
  .hs-form-field .input {
    margin-left: 0 !important; }

.hs-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

select.hs-input:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.hs-input:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.continue-button {
  margin-right: 0.5rem;
  margin-top: 2rem; }

.multi-container {
  padding-left: 0; }

.form-columns-2,
.form-columns-1 {
  max-width: 100% !important; }

.form-columns-1 {
  padding-top: 1rem; }

.hs-form-checkbox-display {
  font-weight: normal; }

.hs-input[type="checkbox"] {
  display: inline-block !important;
  margin-right: 0.25rem; }

.hs-input[type="text"],
.input textarea {
  width: 100% !important; }

legend {
  font-size: 0.875rem;
  font-weight: normal; }

.hs-form-checkbox {
  display: inline-block;
  margin-right: 1rem; }

.auth-container h1,
.auth-container h2 {
  padding-top: 4rem; }

.form-bg,
#hubspotForm1,
#hubspotForm2 {
  border-top: 0.5rem solid #dfe6ec; }

.reg-page {
  background-color: #fff; }
  .reg-page .site-footer {
    display: none; }
  @media (min-width: 1048px) {
    .reg-page .site-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1; }
      .reg-page .site-header .container, .reg-page .site-header .reg-page__secondary__content {
        max-width: 100%; }
    .reg-page .site-header__global,
    .reg-page .site-header {
      background-color: transparent; }
    .reg-page .site-nav-container {
      display: none; }
    .reg-page .site-header__logo {
      width: 30%;
      text-align: center;
      top: 0.5rem; }
      .reg-page .site-header__logo:hover {
        background-color: transparent; }
      .reg-page .site-header__logo img {
        max-width: 15em; } }
  .reg-page .cols-2 {
    grid-gap: 16px 16px; }

/*=============== Header ===============*/
.reg-page__primary {
  background-color: white; }
  .reg-page__primary h1 {
    text-align: center;
    margin-bottom: 0.25em; }
  .reg-page__primary .container, .reg-page__primary .reg-page__secondary__content {
    padding-top: 1.5em;
    max-width: 55em; }

.reg-page__content-wrap {
  z-index: 2;
  position: relative; }
  .reg-page__content-wrap .site-content-primary .container, .reg-page__content-wrap .site-content-primary .reg-page__secondary__content {
    max-width: 55em; }
  @media (min-width: 65em) {
    .reg-page__content-wrap .site-content-primary .container, .reg-page__content-wrap .site-content-primary .reg-page__secondary__content {
      padding-top: 1.5rem; } }
  @media (min-width: 65em) {
    .reg-page__content-wrap {
      width: 70%;
      float: right; } }

.reg-page__secondary {
  border-top: 3px solid #6CB33F;
  display: none; }
  @media (min-width: 65em) {
    .reg-page__secondary {
      display: block;
      width: 30%;
      float: left;
      background-image: linear-gradient(to right, #00334D 0%, #03405f 100%);
      background-repeat: repeat-x;
      background-position: center center;
      background-size: cover;
      color: #fff;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0; } }

.reg-page__secondary__content {
  position: absolute;
  bottom: 0; }
  .reg-page__secondary__content cite {
    color: #fff;
    text-align: right;
    float: right; }
  .reg-page__secondary__content img {
    width: 10em;
    float: right;
    display: block;
    clear: both;
    margin-top: 1rem;
    height: auto; }

.site-wrap {
  background-color: #ecf1f5; }

.campaign-card-section {
  border-bottom: 1px dotted gray;
  margin-bottom: 1rem; }
  .campaign-card-section:last-child {
    border-bottom: none;
    margin-bottom: 0; }

.campaign-card-box {
  border: 1px solid #dfe6ec;
  padding: 1em;
  max-width: 52rem;
  margin-top: 1.5rem; }

.campaign-header {
  max-width: 52rem; }

.campaigns-dropdown {
  max-width: 25rem; }

.color-inherit {
  color: inherit !important; }

.primary-goal-selections {
  max-width: 52rem; }

.primary-goal-selection {
  display: -webkit-flex;
  display: flex;
  padding: 0 1rem;
  border: 1px solid white; }
  .primary-goal-selection input {
    margin: 1.5rem 1rem 0.5rem 0; }
  .primary-goal-selection.active {
    border: 1px solid #dfe6ec; }

.campaigns-hv-pages .textbox-adder {
  width: 42rem;
  display: block; }

.campaigns-hv-pages .thomas-textbox__input {
  margin-top: 0.5rem; }

.campaigns-hv-pages .textbox-adder_remove {
  bottom: 1.25rem; }

.page-header__active {
  font-size: 1.25rem;
  padding: 0rem 1rem;
  margin-bottom: 1rem;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  display: -webkit-flex;
  display: flex;
  color: darkgray; }

.pdf-download-icon svg {
  height: 25px;
  position: absolute;
  right: 200px;
  top: 100px; }

.page-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.section-header__subheader {
  color: #616668; }

.section-header__header {
  margin-bottom: 2rem; }
  .section-header__header small {
    color: darkgray; }

.campaigns_card-group {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2%; }

.campaigns_card-group {
  margin: 2rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

@media (max-width: 767.98px) {
  .page-header__header {
    font-size: 2.5rem; }
  .metrics-items {
    margin-top: 1rem;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
  .campaigns_card-group {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: 1fr;
    -ms-grid-template-rows: 1fr 1fr;
    grid-template-rows: 2fr 2fr; }
  .campaigns_card-group .campaigns-card {
    width: 100%; }
  .session-graph {
    height: 300px; } }

@media (max-width: 575.98px) {
  .page-header__header {
    font-size: 1.75rem; }
  .campaigns-dash_companyName {
    font-size: 1.0625rem; }
  .campaigns-dash-header {
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-content: center;
            align-content: center; }
  .campaign-metrics--datepicker {
    margin-left: 0;
    margin-top: 1rem;
    font-size: 0.875rem;
    -webkit-align-self: center;
            align-self: center; }
    .campaign-metrics--datepicker .date-picker--display {
      margin-left: 0; }
  .metrics-items {
    margin-top: 1rem;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .metrics-items .metrics-item {
      padding: 1rem;
      -webkit-align-self: center;
              align-self: center; }
    .metrics-items .metrics-item-line {
      display: none; }
  .session-graph {
    height: 200px; } }

.p-clickable {
  color: #28628F !important;
  font-weight: 500; }

.campaign-metrics--datepicker {
  position: unset !important;
  margin-left: auto; }
  .campaign-metrics--datepicker .date-picker--dropdown.active {
    top: 4.25rem;
    right: 1.5rem; }
  @media (max-width: 767.98px) {
    .campaign-metrics--datepicker {
      margin: 0; } }

.campaigns-dash-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 1rem;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media (max-width: 767.98px) {
    .campaigns-dash-header {
      -webkit-justify-content: center;
              justify-content: center; } }
  .campaigns-dash-header h3 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    margin-right: 1rem; }
  .campaigns-dash-header p {
    color: #A4ACB3; }

.campaigns-dash_companyName {
  margin: 0; }

.campaigns-dash-metrics {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: start;
          justify-content: start;
  color: #616668;
  font-weight: 400; }
  .campaigns-dash-metrics .item-2 {
    max-width: 50%; }
  @media (max-width: 767px) {
    .campaigns-dash-metrics {
      -webkit-justify-content: center;
              justify-content: center; }
      .campaigns-dash-metrics .item-2 {
        max-width: 100%; } }
  @media (min-width: 870px) {
    .campaigns-dash-metrics .item-2 {
      max-width: 20%; } }
  @media (min-width: 1000px) {
    .campaigns-dash-metrics .item-2 {
      max-width: 30%; } }
  @media (min-width: 1200px) {
    .campaigns-dash-metrics .item-2 {
      max-width: 40%; } }
  @media (min-width: 1300px) {
    .campaigns-dash-metrics .item-2 {
      max-width: 50%; } }

.metrics-items {
  display: -webkit-flex;
  display: flex; }
  @media (max-width: 767.98px) {
    .metrics-items {
      -webkit-justify-content: center;
              justify-content: center; } }

.metrics-item {
  text-align: center;
  padding: 0 2rem; }
  .metrics-item:first-child {
    padding-left: 0;
    display: -webkit-flex;
    display: flex; }

.metrics-item-line {
  width: 1px;
  background: #dfe6ec;
  height: 50%; }

.campaigns-dash-map .map-display {
  height: 275px; }

.campaigns-card:first-child {
  margin: 0.75rem 0;
  height: auto;
  width: 100%; }

.campaigns-card:last-child {
  width: 49%; }
  @media (max-width: 767.98px) {
    .campaigns-card:last-child {
      width: 100%; } }

.metrics-item-cluster {
  margin-left: 10px;
  text-align: left;
  color: #616668; }

.campaigns-card__impressions-and-clicks {
  padding: 1.5rem 0;
  margin-bottom: 2rem;
  width: 100%; }

svg.recharts-surface {
  -webkit-transform: scale(1.02);
          transform: scale(1.02); }

@media (min-width: 576px) {
  .description {
    max-width: 35%; } }

.table {
  border-bottom: 1px solid #dfe6ec; }

.campaign-dash-table th:first-child {
  padding-left: 2rem;
  width: 45%; }

.campaign-dash-table td:first-child {
  padding-left: 2rem; }

.campaign-dash-table th:nth-of-type(2) {
  width: 20%; }

.campaign-dash-table th:nth-of-type(3) {
  width: 10%; }

.campaign-dash-table th:nth-of-type(4) {
  width: 20%; }

.campaign-dash-table th:nth-of-type(5) {
  width: 5%; }

.campaigns-dash-table__company {
  position: relative; }
  .campaigns-dash-table__company span {
    left: -0.5rem;
    position: relative;
    font-size: unset; }

.icon-arrow-down {
  display: inline-block;
  width: 5px; }
  .icon-arrow-down .icon {
    height: 9px;
    margin-bottom: 3px; }

.icon-arrow-up {
  display: inline-block;
  width: 5px; }
  .icon-arrow-up .icon {
    height: 9px;
    margin-bottom: 3px; }

.page-utility {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem 2rem 0rem 1rem;
  max-width: 91.25rem;
  margin: 0 auto;
  -webkit-align-items: center;
          align-items: center; }
  @media (min-width: 576px) {
    .page-utility {
      padding: 1.75rem 3rem 0rem 1.5rem; } }
  @media (min-width: 768px) {
    .page-utility {
      padding: 2rem 4rem 0rem 2rem; } }
  .page-utility .breadcrumb {
    padding: 0; }
  .page-utility .action-icons {
    padding: 0; }
    .page-utility .action-icons .results-control__utility-icons {
      -webkit-align-items: center;
              align-items: center;
      padding-top: 0;
      color: #28628F;
      cursor: pointer; }

@media print {
  h4 {
    margin-top: 0.2rem;
    margin-bottom: 0.3rem; }
  p {
    margin: 0; }
  .table th {
    background-color: #ecf1f5 !important;
    -webkit-print-color-adjust: exact;
    line-height: 0.8; }
  tbody tr {
    line-height: 1; }
  .page-utility {
    display: none; }
  .container--site-header__global {
    display: none; }
  .usabilla_live_button_container {
    display: none; }
  .site-footer {
    display: none; }
  .site-wrap {
    background-color: white;
    margin: 0; }
  .page-header__header {
    display: none; }
  .thomas-pagination {
    display: none; }
  .table-container thead td {
    height: 60px; }
  h4 {
    margin-left: 1rem; }
  .pdf_second-page-subheader {
    position: absolute;
    left: 30px;
    top: 10px; }
    .pdf_second-page-subheader h2 {
      margin-bottom: 0;
      font-size: 1.65rem; }
    .pdf_second-page-subheader p {
      font-size: 1rem;
      margin-bottom: 0.5rem; }
  @page {
    margin: 0.5rem;
    size: 8.5in 11in; } }

.pdf_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #03405f;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  color: white;
  padding: 0.3rem; }

table {
  width: 100%;
  margin-top: 0.5rem; }

.pdf_company-name {
  color: #03405f;
  margin: 1rem 1rem 0rem 1rem;
  font-size: 30px;
  text-align: center; }

.pdf_company-name__subheader {
  color: #03405f;
  font-size: 20px;
  text-align: center; }

.pdf_tbody-td-center {
  border: 1px solid #ecf1f5;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 0.2rem; }

.pdf_td-headers {
  margin-left: 1rem;
  display: inline;
  color: #00334d; }

.page-break-after {
  page-break-after: always; }

.page-break-before {
  page-break-before: always; }

.pdf-second-page-content {
  position: absolute;
  top: 1100px;
  width: 100%;
  margin-top: 40px; }

.pdf_custom-session-graph--wrapper {
  height: 255px;
  width: 800px;
  zoom: 0.85;
  padding: 0.5rem; }

.pdf_created-by {
  margin-left: 1rem; }

.pdf_thomasnet-copyright {
  margin-right: 1rem; }

/* Header and Footer  */
.pdf_header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #03405f;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  color: white;
  padding: 0.5rem;
  z-index: 100; }
  .pdf_header img {
    height: 45px;
    padding: 0.1rem; }
  .pdf_header h3 {
    color: white;
    text-align: bottom;
    margin-bottom: 0.05rem;
    font-weight: 400; }

/* First Page Content */
.pdf_caption-background {
  background-image: url("https://cdn40.thomasnet.com/img40/hero-bg.jpg");
  background-size: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 200;
  height: 8%;
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end; }
  .pdf_caption-background h3 {
    color: white;
    text-align: bottom;
    margin-bottom: 0.05rem;
    font-weight: 400; }
  .pdf_caption-background img {
    display: -webkit-flex;
    display: flex;
    padding: 0.1rem; }

.pdf_first-page-content {
  padding: 0.5rem;
  position: absolute;
  width: 100%;
  top: 90px; }

.pdf_campaign-period {
  font-size: 20px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  padding-bottom: 20px; }
  .pdf_campaign-period p {
    font-size: 18px; }

.pdf_row-container_medium {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 300px;
  margin-bottom: 1rem; }
  .pdf_row-container_medium h2 {
    color: #00334d;
    padding: 0.5rem 0 0 0; }
  .pdf_row-container_medium h3 {
    color: #616668; }
  .pdf_row-container_medium .pdf_pvs-creatives-container {
    display: -webkit-flex;
    display: flex; }
    .pdf_row-container_medium .pdf_pvs-creatives-container .pdf_pvs-creative-0 {
      height: 250px;
      width: 120px;
      padding: 0.3rem; }
    .pdf_row-container_medium .pdf_pvs-creatives-container .pdf_pvs-creative-1 {
      height: 70px;
      width: 275px;
      padding: 0.3rem; }
  .pdf_row-container_medium .pdf_description {
    font-size: 1.0625rem;
    color: #616668;
    padding-left: 1rem; }

.pdf_row-container_small {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 215px;
  margin-bottom: 1rem; }
  .pdf_row-container_small h2 {
    color: #00334d;
    padding: 0.5rem 0 0 0; }
  .pdf_row-container_small h3 {
    color: #616668; }
  .pdf_row-container_small .pdf_pvs-creatives-container {
    display: -webkit-flex;
    display: flex; }
    .pdf_row-container_small .pdf_pvs-creatives-container .pdf_pvs-creative-0 {
      height: 250px;
      width: 120px;
      padding: 0.3rem; }
    .pdf_row-container_small .pdf_pvs-creatives-container .pdf_pvs-creative-1 {
      height: 70px;
      width: 275px;
      padding: 0.3rem; }
  .pdf_row-container_small .pdf_description {
    font-size: 1.0625rem;
    color: #616668; }

.pdf_impressions-and-clicks-container {
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  width: 20%;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly; }
  .pdf_impressions-and-clicks-container .pdf_impressions {
    text-align: center; }
    .pdf_impressions-and-clicks-container .pdf_impressions h2 {
      margin: 0; }
  .pdf_impressions-and-clicks-container .pdf_clicks {
    text-align: center; }
    .pdf_impressions-and-clicks-container .pdf_clicks h2 {
      margin: 0; }

.pdf_audience-by-industry__container {
  display: -webkit-flex;
  display: flex;
  width: 100%; }
  .pdf_audience-by-industry__container .pdf_topcountcard-pie-desc {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
            flex-flow: row;
    width: 100%;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding: 0.5rem; }
    .pdf_audience-by-industry__container .pdf_topcountcard-pie-desc .pdf_topcountcard-piechart {
      display: -webkit-flex;
      display: flex;
      width: 20%; }
      .pdf_audience-by-industry__container .pdf_topcountcard-pie-desc .pdf_topcountcard-piechart svg {
        margin: 0 !important;
        margin-right: 2rem; }
    .pdf_audience-by-industry__container .pdf_topcountcard-pie-desc .pdf_count-card-row-container {
      padding: 1.5rem;
      width: 50%; }

.pdf_disclaimer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  padding: 1rem; }
  .pdf_disclaimer .pdf_disclaimer-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
            flex-flow: row; }

/* Common Style  */
.gray-border {
  border: 1px solid #a4acb3; }

.display-flex-row {
  -webkit-flex-flow: row;
          flex-flow: row; }

.display-flex-col {
  -webkit-flex-flow: column;
          flex-flow: column; }

.font-size-xxs {
  font-size: 10px; }


.vgi--video {
  background-color: #ced2d6;
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .vgi--video img {
    cursor: pointer; }

.vgi--title {
  color: #28628F;
  font-size: 1.75rem;
  padding: 0.5rem 0; }

.vgi--subtitle {
  color: #616668;
  font-size: 1.25rem;
  padding: 0.5rem 0;
  padding-top: 0; }

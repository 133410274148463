.session-info__count {
  font-size: 30px;
  color: #03405f; }

.session-info__header {
  padding-left: 10px;
  font-size: 1.25rem; }

.session-info__title {
  font-weight: 400;
  color: #6CB33F; }

.session-info__dates {
  color: #616668;
  font-weight: 100; }

.session-info__return {
  padding: 0.5rem;
  font-size: 0.875rem; }

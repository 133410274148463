@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";
@import "global/bootstrap/mixins";
@import "global/global-mixins/global-mixins";

.site-wrap {
  background-color: #ecf1f5;
}

.campaign-card-section {
  border-bottom: 1px dotted gray;
  margin-bottom: 1rem;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.campaign-card-box {
  border: 1px solid #dfe6ec;
  padding: 1em;
  max-width: 52rem;
  margin-top: 1.5rem;
}

.campaign-header {
  max-width: 52rem;
}
.campaigns-dropdown {
  max-width: 25rem;
}

.color-inherit {
  color: inherit !important;
}

.primary-goal-selections {
  max-width: 52rem;
}

.primary-goal-selection {
  display: flex;
  padding: 0 1rem;
  border: 1px solid white;
  input {
    margin: 1.5rem 1rem 0.5rem 0;
  }
  &.active {
    border: 1px solid #dfe6ec;
  }
}

.campaigns-hv-pages {
  .textbox-adder {
    width: 42rem;
    display: block;
  }
  .thomas-textbox__input {
    margin-top: 0.5rem;
  }
  .textbox-adder_remove {
    bottom: 1.25rem;
  }
}

.page-header__active {
  font-size: 1.25rem;
  padding: 0rem 1rem;
  margin-bottom: 1rem;
  align-items: flex-end;
  display: flex;
  color: darkgray;
}
.pdf-download-icon svg {
  height: 25px;
  position: absolute;
  right: 200px;
  top: 100px;
}

.page-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.section-header__subheader {
  color: #616668;
}

.section-header__header {
  margin-bottom: 2rem;
  small {
    color: darkgray;
  }
}
//------------------------------ TEMPORARY CAMPAIGN DASHBOARD STYLES FOR HIDDEN COMPONENTS  ------------------------------------------------------------------

.campaigns_card-group {
  margin: $baseline * 4 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-column-gap: 2%;
}

.campaigns_card-group {
  margin: $baseline * 4 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

// ----------------------------- IPAD View -------------------------------------------------------

@include media-breakpoint-down(sm) {
  .page-header__header {
    font-size: $font-size-large;
  }
  .metrics-items {
    margin-top: 1rem;
    flex-wrap: wrap;
  }
  .campaigns_card-group {
    margin: $baseline * 4 0;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-template-rows: 1fr 1fr;
    grid-template-rows: 2fr 2fr;
  }
  .campaigns_card-group {
    .campaigns-card {
      width: 100%;
    }
  }
  .session-graph {
    height: 300px;
  }
}

@include media-breakpoint-down(xs) {
  .page-header__header {
    font-size: $font-size-medium;
  }
  .campaigns-dash_companyName {
    font-size: $font-size-small;
  }
  .campaigns-dash-header {
    flex-flow: column;
    align-content: center;
  }
  .campaign-metrics--datepicker {
    margin-left: 0;
    margin-top: 1rem;
    font-size: $font-size-xsmall;
    align-self: center;
    .date-picker--display {
      margin-left: 0;
    }
  }
  .metrics-items {
    margin-top: 1rem;
    flex-wrap: wrap;
    .metrics-item {
      padding: 1rem;
      align-self: center;
    }
    .metrics-item-line {
      display: none;
    }
  }
  .session-graph {
    height: 200px;
  }
}

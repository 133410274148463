@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";
@import "global/bootstrap/mixins";
@import "global/global-mixins/global-mixins";

.page-utility {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem 0rem 1rem;
  max-width: 91.25rem;
  margin: 0 auto;
  align-items: center;
  @include media-breakpoint-up(sm) {
    padding: 1.75rem 3rem 0rem 1.5rem;
  }
  @include media-breakpoint-up(md) {
    padding: 2rem 4rem 0rem 2rem;
  }
  .breadcrumb {
    padding: 0;
  }

  .action-icons {
    padding: 0;
    .results-control__utility-icons {
      align-items: center;
      padding-top: 0;
      color: $blue;
      cursor: pointer;
    }
  }
}

.thomas-table {
  margin-top: 0.5rem;
  margin-bottom: 2rem; }
  .thomas-table a:hover {
    color: unset !important;
    text-decoration: none !important; }

.thomas-table__grid {
  display: -ms-grid;
  -ms-grid-columns: 20% 15% 13% 12% 10% 6% 12% 12%;
  display: grid;
  grid-template-columns: 20% 15% 13% 12% 10% 6% 12% 12%; }
  .thomas-table__grid div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(4) {
    -ms-grid-column: 4;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(5) {
    -ms-grid-column: 5;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(6) {
    -ms-grid-column: 6;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(7) {
    -ms-grid-column: 7;
    -ms-grid-row: 1; }
  .thomas-table__grid div:nth-child(8) {
    -ms-grid-column: 8;
    -ms-grid-row: 1; }
  .thomas-table__grid > * {
    padding: 0.33333rem;
    border-left: 1px solid #A4ACB3;
    display: flex;
    align-items: center;
    justify-content: center; }
    .thomas-table__grid > * :first-child {
      justify-content: start; }

.grid-col-3 {
  grid-template-columns: repeat(3, 1fr); }

.thomas-table--header {
  background-color: #dfe6ec;
  color: black;
  border-top: 1px solid #A4ACB3;
  border-right: 1px solid #A4ACB3;
  font-weight: bolder !important; }
  .thomas-table--header > *:first-child {
    justify-content: start; }

.thomas-table--row {
  color: #616668;
  transition: 0.2s;
  border-right: 1px solid #A4ACB3;
  word-break: break-all;
  word-break: break-word; }
  .thomas-table--row:nth-child(odd) {
    background-color: #ecf1f5; }
  .thomas-table--row:nth-child(even) {
    background-color: #fff; }
  .thomas-table--row:last-child {
    border-bottom: 1px solid #A4ACB3; }

.thomas-row-clickable:hover {
  background-color: #dfe6ec;
  cursor: pointer; }

.thomas-table--show-more {
  color: #28628F;
  font-weight: bold;
  font-size: 0.875rem;
  justify-content: start;
  padding: 0.5rem 0.33333rem; }
  .thomas-table--show-more span:first-child {
    padding-right: 0.5rem; }

.thomas-table--title {
  display: flex;
  align-items: center; }

.thomas-table--icons {
  color: #28628F;
  font-weight: bold;
  padding-top: 0.5rem; }
  .thomas-table--icons * {
    margin-right: 0.5rem; }

.thomas-table__title {
  color: #28628F;
  font-weight: 500;
  display: block; }

.thomas-table__funnelAnalysis {
  color: #28628F; }

.thomas-table__pagesViewed,
.thomas-table__sessionCount {
  justify-content: center; }

.table-cell__with-icons {
  padding-right: 0.5rem; }

.thomas-table__mobile {
  border: 1px solid #A4ACB3; }

.thomas-table__mobile--item {
  padding: 1rem 0; }
  .thomas-table__mobile--item:nth-child(odd) {
    background-color: #fff; }
  .thomas-table__mobile--item:nth-child(even) {
    background-color: #ecf1f5; }
  .thomas-table__mobile--item:hover {
    background-color: #eaf5e3;
    cursor: pointer; }

.thomas-table__mobile--row {
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  font-size: 0.875rem;
  padding: 0.125rem 0.5rem; }
  .thomas-table__mobile--row :last-child {
    font-weight: bold; }
  .thomas-table__mobile--row:first-child :last-child {
    color: #28628F; }

.row-loader {
  height: 36px !important;
  grid-column: 1 / 4; }

.analytics-table .thomas-table__url * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.0625rem; }

.analytics-table .thomas-table__url span {
  margin-left: 0.5rem !important; }

.analytics-table .thomas-table--title__sortable {
  text-decoration: underline;
  color: #28628F;
  cursor: pointer; }

.arrow-asc {
  padding: 0 0.25rem;
  transform: rotate(180deg); }

.arrow-desc {
  padding: 0 0.25rem; }

@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";
@import "global/bootstrap/mixins";
@import "global/global-mixins/global-mixins";

.company-info {
  display: -ms-grid;
  -ms-grid-columns: 180px 360px 1fr;
  border-bottom: 2px dotted $gray;
  padding: $baseline * 2 $baseline;
  padding-bottom: $baseline * 2;
  .company-info__logo {
    -ms-grid-column: 1;
  }
  .company-info__name {
    -ms-grid-column: 2;
  }
  .company-info__description {
    -ms-grid-column: 3;
  }
  display: grid;
  grid-template-columns: 180px 360px auto;

  @include media-breakpoint-down(sm) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
  }
}

.company-info--logo-unavailable {
  display: -ms-grid;
  -ms-grid-columns: 0px 360px auto;
  border-bottom: 2px dotted $gray;
  padding: $baseline * 2 $baseline;
  padding-bottom: $baseline * 2;
  .company-info__logo {
    -ms-grid-column: 1;
  }
  .company-info__name {
    -ms-grid-column: 2;
  }
  .company-info__description {
    -ms-grid-column: 3;
  }
  display: grid;
  grid-template-columns: 0px 360px auto;

  @include media-breakpoint-down(sm) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
  }
}

.company-info--logo-unavailable {
  display: grid;
  grid-template-columns: 0px 360px auto;
  border-bottom: 2px dotted $gray;
  padding: $baseline * 2 $baseline;
  padding-bottom: $baseline * 2;

  @include media-breakpoint-down(sm) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
  }
}

.company-info__logo {
  display: flex;
  justify-content: center;
  @include media-breakpoint-down(sm) {
    padding-bottom: $baseline;
  }
  img {
    padding: 0 $baseline;
    max-width: 100%;
  }
}

.company-info__logo--display-none {
  display: none;
}

.company-info__name {
  padding: 0 $baseline 0 $baseline * 3;
}

.company-info__title-box {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.company-info__details-box {
  margin-top: $baseline;
  border-top: 1px solid $gray;
}

.company-info__details-row {
  display: flex;
  flex-flow: row;
}

.company-info__details-icon {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.company-info__details-item {
  font-size: $font-size-xsmall;
  padding: 5px;
  border-bottom: 1px solid $gray;
  *:last-child {
    padding-left: 5px;
    display: inline-block;
  }
}

.company-info__description {
  display: flex;
  align-items: center;
  margin: $baseline * 6 $baseline * 3 $baseline * 2 $baseline * 2;
  padding-left: $baseline * 3;
  border-left: $gray 1px solid;

  @include media-breakpoint-down(md) {
    grid-area: 2 / 1 / 2 / 4;
    margin: $baseline * 2 $baseline;
    border: none;
    padding: 0;
  }

  @include media-breakpoint-down(sm) {
    grid-area: unset;
  }
}

.reg-container {
  padding-right: 1rem; }
  .reg-container .copyright-info {
    text-align: center; }

.registration-background-img {
  display: block;
  width: 30%;
  float: left;
  background-image: linear-gradient(to right, #00334d 0, #03405f 100%);
  background-repeat: repeat-x;
  background-position: center center;
  background-size: cover;
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  border-top: 3px solid #6CB33F; }

.bg-image-logo {
  position: absolute;
  max-width: 15em;
  text-align: center;
  top: 0.5rem; }

.bg-image-text {
  color: #fff;
  position: absolute;
  bottom: 0;
  padding: 3rem 2rem 3rem 2rem;
  max-width: 91.25rem;
  margin: 0 auto; }

.registration-page {
  width: 70%;
  float: right;
  background: white; }

.progress-steps {
  list-style: none;
  padding: 0 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 740px;
  margin: auto;
  margin-bottom: 3rem; }

.progress-steps__step {
  font-size: 1.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 46px; }

.step-2:before {
  content: " ";
  height: 2px;
  width: 12rem;
  background-color: #A4ACB3;
  position: absolute;
  top: 1.25rem;
  left: 9.25rem; }

.step-3:before {
  content: " ";
  height: 2px;
  width: 12rem;
  background-color: #A4ACB3;
  position: absolute;
  top: 1.25rem;
  right: 9.25rem; }

.progress-steps__step__label {
  font-size: 1rem;
  font-weight: 500;
  color: #A4ACB3;
  position: absolute;
  top: 2.5rem; }
  .progress-steps__step__label.active {
    color: #03405f; }

.label-completed {
  color: #6CB33F; }

@media (max-width: 1047.98px) {
  .step-2:before {
    width: 11.5rem; }
  .step-3:before {
    width: 11.5rem; } }

@media (max-width: 767.98px) {
  .progress-steps {
    padding: 0 2rem; }
  .progress-steps__step__label {
    font-size: 0.75rem;
    width: 75px;
    text-align: center; }
  .progress-steps__step {
    font-size: 1.25rem;
    width: 36px; }
  .step-2:before {
    width: 4.75rem;
    top: 1rem;
    left: 4.5rem; }
  .step-3:before {
    width: 4.75rem;
    top: 1rem;
    right: 4.5rem; } }

.completed:before {
  background: #6CB33F; }

.progress-steps__step__icon {
  display: flex;
  justify-content: center;
  color: #A4ACB3;
  border: 2px #A4ACB3 solid;
  background-color: transparent;
  border-radius: 1.75rem;
  padding: 0.25rem; }

.active .progress-steps__step__icon {
  color: #fff;
  background: #03405f;
  border: 2px #03405f solid; }

.active::before {
  background: #03405f; }

.completed .progress-steps__step__icon {
  color: #fff;
  background: #6CB33F;
  border: 2px #6CB33F solid; }

.reg-page__header {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 4rem 0 2rem 0; }

.form-webtrax--header {
  border-top: 0.5rem solid #dfe6ec;
  background-color: #ecf1f5;
  padding-top: 1rem;
  padding-left: 1rem; }

.form--webtrax-reg__url {
  border-radius: 0;
  background-color: #ecf1f5;
  padding: 1rem 2rem !important; }
  .form--webtrax-reg__url .actions {
    text-align: center; }
  .form--webtrax-reg__url p {
    text-align: center; }

.return-link {
  text-decoration: underline;
  cursor: pointer; }

.forgot-password-link {
  color: #28628F !important;
  text-decoration: underline !important;
  cursor: pointer; }

.flex-end-btn {
  display: flex;
  justify-content: flex-end; }

.login-component {
  max-width: 30rem;
  margin: auto;
  height: 10rem; }
  .login-component .form-div {
    display: block; }
  .login-component button {
    float: right;
    margin-bottom: 1rem; }

.form--webtraxs-reg__error {
  height: 1.5rem;
  color: #BC4239; }

.form--error {
  border-color: #BC4239; }

.form--error:focus {
  box-shadow: 0 0 3px 1px #BC4239;
  border-color: #BC4239; }

.form-div {
  display: flex;
  align-items: flex-end; }

.url-btn-container {
  border: 1px solid #ecf1f5; }

.form--webtrax-reg__url-input {
  flex: 1;
  margin-right: 1rem; }

fieldset {
  max-width: unset !important; }

.hs-form-field {
  padding: 0 0.25rem; }
  .hs-form-field label {
    width: unset !important;
    margin-top: 1rem; }
  .hs-form-field .input {
    margin-left: 0 !important; }

.hs-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

select.hs-input:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.hs-input:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.continue-button {
  margin-right: 0.5rem;
  margin-top: 2rem; }

.multi-container {
  padding-left: 0; }

.form-columns-2,
.form-columns-1 {
  max-width: 100% !important; }

.form-columns-1 {
  padding-top: 1rem; }

.hs-form-checkbox-display {
  font-weight: normal; }

.hs-input[type="checkbox"] {
  display: inline-block !important;
  margin-right: 0.25rem; }

.hs-input[type="text"],
.input textarea {
  width: 100% !important; }

legend {
  font-size: 0.875rem;
  font-weight: normal; }

.hs-form-checkbox {
  display: inline-block;
  margin-right: 1rem; }

.auth-container h1,
.auth-container h2 {
  padding-top: 4rem; }

.form-bg,
#hubspotForm1,
#hubspotForm2 {
  border-top: 0.5rem solid #dfe6ec; }

.reg-page {
  background-color: #fff; }
  .reg-page .site-footer {
    display: none; }
  @media (min-width: 1048px) {
    .reg-page .site-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1; }
      .reg-page .site-header .container, .reg-page .site-header .reg-page__secondary__content {
        max-width: 100%; }
    .reg-page .site-header__global,
    .reg-page .site-header {
      background-color: transparent; }
    .reg-page .site-nav-container {
      display: none; }
    .reg-page .site-header__logo {
      width: 30%;
      text-align: center;
      top: 0.5rem; }
      .reg-page .site-header__logo:hover {
        background-color: transparent; }
      .reg-page .site-header__logo img {
        max-width: 15em; } }
  .reg-page .cols-2 {
    grid-gap: 16px 16px; }

/*=============== Header ===============*/
.reg-page__primary {
  background-color: white; }
  .reg-page__primary h1 {
    text-align: center;
    margin-bottom: 0.25em; }
  .reg-page__primary .container, .reg-page__primary .reg-page__secondary__content {
    padding-top: 1.5em;
    max-width: 55em; }

.reg-page__content-wrap {
  z-index: 2;
  position: relative; }
  .reg-page__content-wrap .site-content-primary .container, .reg-page__content-wrap .site-content-primary .reg-page__secondary__content {
    max-width: 55em; }
  @media (min-width: 65em) {
    .reg-page__content-wrap .site-content-primary .container, .reg-page__content-wrap .site-content-primary .reg-page__secondary__content {
      padding-top: 1.5rem; } }
  @media (min-width: 65em) {
    .reg-page__content-wrap {
      width: 70%;
      float: right; } }

.reg-page__secondary {
  border-top: 3px solid #6CB33F;
  display: none; }
  @media (min-width: 65em) {
    .reg-page__secondary {
      display: block;
      width: 30%;
      float: left;
      background-image: linear-gradient(to right, #00334D 0%, #03405f 100%);
      background-repeat: repeat-x;
      background-position: center center;
      background-size: cover;
      color: #fff;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0; } }

.reg-page__secondary__content {
  position: absolute;
  bottom: 0; }
  .reg-page__secondary__content cite {
    color: #fff;
    text-align: right;
    float: right; }
  .reg-page__secondary__content img {
    width: 10em;
    float: right;
    display: block;
    clear: both;
    margin-top: 1rem;
    height: auto; }

@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";
@import "global/bootstrap/mixins";
@import "global/global-mixins/global-mixins";

.card-group {
  display: -ms-grid;
  -ms-grid-columns: 48% 2% 50%;
  display: grid;
  grid-template-columns: 48% 50%;
  grid-gap: 2%;
  grid-row-gap: 1.5rem;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
  }

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
  }
}

.card-group--left {
  margin: $baseline * 4 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }
  @include media-breakpoint-down(sm) {
    height: unset;
  }
  -ms-grid-column: 1;
}

.card-group--right {
  margin: $baseline * 4 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include media-breakpoint-down(md) {
    margin: 0;
  }
  @include media-breakpoint-down(sm) {
    height: unset;
  }
  -ms-grid-column: 3;
}

.visitor-pins-disclaimer {
  margin: 0.25rem 0;
  font-style: italic;
  font-size: $font-size-small;
  color: $gray-dark;
  font-weight: 300;
}

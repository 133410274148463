.thomas-icon {
  color: #28628F;
  font-size: 1.75rem; }

.icon-red {
  color: #BC4239; }

.icon-green {
  color: #6CB33F; }

.icon-lightblue {
  color: #28628F;
  opacity: 0.5; }

.icon_funnel-analysis {
  position: relative;
  width: 23px;
  bottom: 2px; }

.icon-name-description {
  color: #212529;
  font-size: 1rem; }

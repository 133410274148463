.session-detail-row {
  padding: 0.75rem; }

.row-content__borderless {
  display: flex; }

.row-content {
  display: flex;
  border-bottom: 0.5px dotted grey;
  padding-bottom: 10px; }

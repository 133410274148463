.total-sessions-graph {
  width: 100%;
  height: 480px; }

.depth-length-loader {
  height: 200px;
  display: flex;
  align-items: center; }
  .depth-length-loader .sk-circle {
    width: 120px;
    height: 120px;
    margin: 20px auto 0 auto; }

.graph-wrapper {
  width: 100%;
  height: 100%; }
  .graph-wrapper svg text {
    font-size: 16px !important;
    fill: #A4ACB3 !important; }
  .graph-wrapper svg line {
    stroke: #A4ACB3 !important; }

.results-filter--content {
  flex-direction: column;
  width: 100%; }
  @media (max-width: 767.98px) {
    .results-filter--content {
      padding-left: 25px; } }

.results-filter--item {
  margin: 0 0.5rem 0 0;
  position: relative; }

.filterby--label {
  position: relative;
  color: #28628F;
  cursor: pointer; }

.results-filter--show-filters_button {
  display: none;
  cursor: pointer; }
  @media (max-width: 1047.98px) {
    .results-filter--show-filters_button {
      display: block;
      position: absolute;
      left: 5.5rem;
      top: -1rem;
      font-size: 36px;
      color: #6CB33F; } }
  @media (max-width: 767.98px) {
    .results-filter--show-filters_button {
      left: -1.5rem; } }
  .results-filter--show-filters_button.active {
    transform: rotate(180deg);
    top: -0.5rem; }

.results-filter--content-filters {
  margin-bottom: 0.5rem;
  display: flex; }
  .results-filter--content-filters > * {
    flex: 3; }
  .results-filter--content-filters :last-child {
    flex: 1; }
  @media (max-width: 1047.98px) {
    .results-filter--content-filters {
      display: none; }
      .results-filter--content-filters.active {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 1fr 1fr; } }
  @media (max-width: 767.98px) {
    .results-filter--content-filters.active {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(5, 1fr); } }

.results-filter--index-pagination-filters {
  margin-bottom: 0.5rem;
  display: flex; }

@media (max-width: 1047.98px) {
  .filter-referer {
    grid-area: 1 / 1 / 1 / 3; }
  .filter-target {
    grid-area: 1 / 3 / 1 / 5; }
  .filter-action {
    grid-area: 1 / 5 / 1 / 7; }
  .filter-search {
    margin-top: 1rem;
    grid-area: 2 / 1 / 2 / 6; }
  .filter-apply {
    margin-top: 1rem; } }

@media (max-width: 767.98px) {
  .filter-referer,
  .filter-target,
  .filter-action,
  .filter-search,
  .filter-apply {
    width: 100%;
    grid-area: unset; } }

.results-filter__options_search-label {
  color: #616668;
  font-size: 12px;
  position: absolute;
  top: -20px;
  white-space: nowrap; }

.smaller-item {
  font-size: 0.875rem; }

.results-filter__active_items {
  display: block; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .results-filter__active_items {
      display: block; } }

.results-filter__category {
  padding-right: 2rem; }

.results-filter__category-title {
  font-size: 0.875rem;
  margin: 0.5rem 0 0.5rem 0; }
  .results-filter__category-title span {
    cursor: pointer;
    font-weight: 500;
    color: #3CB4E7; }
  .results-filter__category-title hr {
    border: 0;
    height: 1px;
    border-bottom: 0.5px dotted grey; }

.results-filter__category-title-bold {
  font-weight: 800; }

.results-filter__active-filters {
  display: flex;
  flex-wrap: wrap; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .results-filter__active-filters {
      width: 100%; } }
  .results-filter__active-filters > * {
    margin: 0 0.5rem 0.5rem 0; }

.results-filter__active-item {
  padding: 0.25rem 0;
  color: #3CB4E7;
  display: flex; }

.results-filter__active-item__label {
  margin-right: 0.5rem;
  display: flex;
  font-weight: bold; }

.results-filter__active-item-container {
  display: flex; }
  .results-filter__active-item-container > span {
    margin: 0 0 0 0.5rem; }

.results-filter__active-item-append {
  margin: 0 0.5rem 0 0; }

.results-filter__active_close {
  padding-left: 10px;
  font-weight: bold;
  cursor: pointer; }

.rw-input {
  padding: 0 0.5rem; }

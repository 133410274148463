@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";
@import "global/bootstrap/mixins";
@import "global/global-mixins/global-mixins";

/* ----------------------------
    Component specific styles
---------------------------- */
.date-picker {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}
.date-picker--header {
  display: flex;
  align-items: center;
  // @include media-breakpoint-down(md) {
  //     flex-direction: column;
  // }
}
.date-picker--header-title {
  color: $blue;
  font-size: $font-size-default;
  font-weight: bold;
}
.date-picker--display {
  display: flex;
  z-index: 11;
  margin-left: 10px;
  background-color: white;
  border: 1px solid #dddddd;
  cursor: pointer;
  &.active {
    border-bottom: 1px solid white;
  }
}
.date-picker--display-range {
  margin: 10px;
  color: $blue-dark;
}
.date-picker--display-arrow {
  padding: 10px;
  background-color: $gray;
  &.active {
    transform: rotate(180deg);
  }
}

.date-picker--months {
  text-align: center;
}
.date-picker--actions {
  text-align: center;
}
.date-picker--actions-dates {
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  border-bottom: 1px dotted grey;
  padding-bottom: 10px;
}
.grid1 {
  grid-column: 1;
  grid-row: 1;
}
.grid2 {
  grid-column: 1;
  grid-row: 2;
}
.date-picker--input {
  width: 100px;
  margin: auto;
  padding: 5px;
}
.input-error {
  box-shadow: 0px 0px 8px 3px $red;
}
.date-picker--label {
  font-weight: 400;
  margin: auto;
}

.date-picker--actions-error {
  font-size: $font-size-xsmall;
  font-weight: bold;
  color: $red;
}
.date-picker--actions-buttons {
  margin: 10px 0px;
  button {
    margin: 5px;
  }
}

.date-picker--dropdown {
  display: none;
  &.active {
    display: block;
    position: absolute;
    top: 2.75rem;
    right: 0;
    width: 700px;
    z-index: 10;
    background-color: white;
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 1rem;
    border: 1px solid #dddddd;
    box-shadow: 0px 2px 2px #dddddd;

    @include mq(mediumv) {
      top: 3rem;
    }
    @include mq(smallv) {
      top: 3.25rem;
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
      grid-template-rows: 2fr 1fr;
      width: unset;
    }
  }
}

/* ----------------------------
    Datepicker Overrides
---------------------------- */
.react-datepicker {
  border: none;
  font-family: $font-family-base;
}
.react-datepicker__day {
  border: 0.5px solid lightgrey;
  margin: 0;
}
.react-datepicker__day:hover {
  border-radius: 0;
  background-color: $gray;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-range-start,
.react-datepicker__day--in-range-end,
.react-datepicker__day--in-range {
  border-radius: 0;
  background-color: $cyan;
}
.react-datepicker__day--outside-month {
  color: transparent;
  background-color: transparent;
  pointer-events: none;
}
.react-datepicker__navigation--previous {
  border-right-color: $blue;
}
.react-datepicker__navigation--next {
  border-left-color: $blue;
}
.react-datepicker__header {
  padding: 0;
  margin: 0.4rem;
  margin-bottom: 0;
  border: 0.5px solid lightgrey;
  background-color: $gray;
  border-radius: 0;
}
.react-datepicker__current-month {
  color: $blue;
}
.react-datepicker__month {
  margin: 0;
  padding: 0.4rem;
  padding-top: 0;
}
.react-datepicker__month-container:last-child {
  padding-left: 25px;
}
.react-datepicker__day-names,
.react-datepicker__triangle {
  display: none;
}

@include media-breakpoint-down(sm) {
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }
}

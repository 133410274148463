.webtrax-loading {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00334d;
  /* background-color: #ecf1f5; */ }
  .webtrax-loading .webtrax-loading-logo {
    width: 200px;
    position: absolute;
    left: 50%;
    margin-left: -168px;
    top: 30%;
    opacity: 0.2; }

.wt-loading-exit {
  opacity: 1; }

.wt-loading-exit-active {
  opacity: 0;
  transition: all 500ms ease-out; }
  .wt-loading-exit-active .webtrax-loading-logo {
    opacity: 0.2; }

.page-header {
  position: relative; }

.help-img {
  font-size: 2.5rem;
  position: absolute;
  right: 0.75rem;
  top: 6.5rem;
  color: #6CB33F;
  cursor: pointer; }
  @media (max-width: 1047.98px) {
    .help-img {
      top: 8.5rem;
      right: 0.75rem; } }
  @media (max-width: 767.98px) {
    .help-img {
      top: 6rem;
      right: 0.25rem; } }
  @media (max-width: 575.98px) {
    .help-img {
      font-size: 1.5rem;
      top: 6rem;
      right: 0.25rem; } }

.page-header--icon {
  width: 2.5rem !important;
  height: 2.5rem !important;
  padding-right: 10px; }

.page-header--sub {
  font-size: 1.0625rem;
  color: #616668;
  font-weight: 200; }

.textbox-adder {
  display: grid;
  grid-template-columns: 70%;
  position: relative;
  margin-bottom: 2rem; }
  @media (max-width: 1047.98px) {
    .textbox-adder {
      grid-template-columns: 95%; } }

.textbox-adder_box {
  position: relative;
  display: flex;
  align-items: center; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .textbox-adder_box {
      width: 75%; } }

.textbox-adder_textbox {
  width: 100%;
  margin: 0.5rem 0; }

.textbox-adder_add {
  font-size: 2.5rem;
  color: #6CB33F;
  position: absolute;
  bottom: 0px;
  left: calc(69% + 20px);
  cursor: pointer; }
  @media (max-width: 1047.98px) {
    .textbox-adder_add {
      left: unset;
      right: 5px; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .textbox-adder_add {
      left: calc(75% + 20px); } }

.textbox-adder_remove {
  font-size: 1.5rem;
  font-weight: bold;
  color: #28628F;
  position: absolute;
  bottom: 0.75rem;
  right: 1rem;
  cursor: pointer; }

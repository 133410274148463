.empty-high-value-actions {
  background-color: #dfe6ec;
  border: 1px solid #A4ACB3;
  font-size: 1.25rem;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem; }
  .empty-high-value-actions div {
    margin: 0.5rem; }
    .empty-high-value-actions div img {
      margin-right: 0.5rem;
      margin-left: 2rem; }

.hVActions-header {
  font-weight: 600;
  padding: 1rem; }

.site-content__primary .tet-class iframe {
  margin-top: -3rem !important; }

.thomas-textbox {
  margin-bottom: 0.5rem; }

.thomas-textbox__label {
  color: #616668;
  display: flex; }

.thomas-textbox__sublabel {
  color: #616668;
  font-weight: 100;
  font-style: italic;
  padding-left: 0.5rem; }

.flex-col {
  display: flex;
  flex-direction: column; }

.details-btn {
  background-color: #ecf1f5;
  border: #dfe6ec 1px solid;
  padding: 0px 0.5rem;
  color: #28628F;
  font-weight: bold;
  width: 65px;
  height: 25px; }

.section-footer {
  margin-top: 0.5rem; }

.section-header__secondary {
  float: left;
  margin: 0.5rem 0.5rem 0 0; }

.results-timeline--header {
  display: flex;
  align-items: center;
  margin: 0; }

.results-timeline--date {
  color: #28628F;
  padding: 0.5rem 0; }

.results-timeline__row {
  border-left: 2px solid #6CB33F;
  margin-left: 0.5rem;
  padding: 0.5rem 0 0.5rem 1rem; }

.results-timeline__card-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.25rem;
  margin: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: #ecf1f5 1px solid;
  color: #28628F; }
  .results-timeline__card-header .timeline--location {
    font-size: 1.0625rem;
    font-weight: 500; }
  .results-timeline__card-header small {
    color: #616668;
    font-size: 0.875rem;
    font-weight: 200;
    margin-left: 0.5rem; }

.results-timeline__card-title {
  margin: 0 1rem 0 0.5rem; }

.results-timeline__card--details-show {
  cursor: pointer;
  margin-left: auto;
  padding: 0.25rem; }
  .results-timeline__card--details-show span {
    font-weight: 400;
    font-size: 1.0625rem;
    color: #28628F; }
  .results-timeline__card--details-show:hover {
    background-color: #dfe6ec;
    cursor: pointer; }

.results-timeline__card-plus {
  position: absolute;
  font-size: 32px;
  color: #6CB33F;
  left: -0.5px;
  top: -2px;
  cursor: pointer; }
  .results-timeline__card-plus.active {
    top: -4px;
    left: 2px; }

.results-timeline__card-date {
  font-size: 1.25rem;
  margin: 0 0.5rem;
  color: #212529; }

.date-icon {
  margin-left: 0.5rem;
  margin-right: 0 !important; }

.results-timeline__card-details {
  display: -ms-grid;
  -ms-grid-columns: 15% 20% 20% 25% 20%;
  display: grid;
  grid-template: 1fr / 15% 20% 20% 25% 20%;
  margin: 0.5rem 1rem; }
  .results-timeline__card-details div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .results-timeline__card-details div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .results-timeline__card-details div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1; }
  .results-timeline__card-details div:nth-child(4) {
    -ms-grid-column: 4;
    -ms-grid-row: 1; }
  .results-timeline__card-details div:nth-child(5) {
    -ms-grid-column: 5;
    -ms-grid-row: 1; }
  @media (max-width: 767.98px) {
    .results-timeline__card-details {
      display: flex;
      flex-direction: column; } }

.results-timeline__card-stat {
  display: block;
  font-size: 1.0625rem; }
  .results-timeline__card-stat :first-child {
    font-weight: 200; }
  @media (max-width: 767.98px) {
    .results-timeline__card-stat {
      display: flex; } }

.referrer-link {
  font-weight: bold; }

.results-timeline__card-icons {
  font-size: 1.75rem; }
  .results-timeline__card-icons * {
    margin-right: 0.5rem; }
    @media (max-height: 46em) and (min-width: 65em) {
      .results-timeline__card-icons * {
        width: 20px; } }
    @media (max-height: 40em) and (min-width: 65em) {
      .results-timeline__card-icons * {
        width: 18px; } }

.results-timeline__card-icon {
  position: relative;
  top: 10px;
  left: -2rem;
  font-size: 1.75rem;
  color: #BC4239;
  background-color: #ecf1f5; }

.results-timeline__card-icon-2 {
  position: relative;
  top: 50px;
  left: -3.75rem;
  font-size: 1.75rem;
  color: #BC4239;
  background-color: #ecf1f5; }

.results-timeline__card-icon-n {
  position: relative;
  top: 10px;
  left: -23px; }
  @media (max-height: 46em) and (min-width: 65em) {
    .results-timeline__card-icon-n {
      width: 9px;
      left: -19px; } }
  @media (max-height: 40em) and (min-width: 65em) {
    .results-timeline__card-icon-n {
      width: 8px;
      left: -17px; } }

.empty-search-results {
  color: #28628F;
  margin: 0.5rem;
  padding: 0.5rem;
  font-size: 1.25rem; }
  .empty-search-results span {
    text-decoration: underline;
    cursor: pointer; }

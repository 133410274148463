.high-value-page-settings {
  margin: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dotted #A4ACB3; }

.high-value-page-settings__subheader {
  font-size: 1.0625rem;
  font-weight: 400;
  margin-bottom: 1.5rem; }

.high-value-page-settings__categories {
  margin: 0.5rem 0; }

.hvp-textbox {
  margin-left: 0.5rem; }

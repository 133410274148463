.basic-settings {
  margin: 1rem;
  border-bottom: 1px dotted #A4ACB3; }

.basic-settings__half-row {
  display: -ms-grid;
  -ms-grid-columns: 37% 37% 1fr;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 35% 35% auto; }
  .basic-settings__half-row .thomas-textbox {
    -ms-grid-column: 1; }
  .basic-settings__half-row .basic-settings--timezone {
    -ms-grid-column: 2; }
  @media (max-width: 1047.98px) {
    .basic-settings__half-row {
      grid-template-columns: 47.5% 47.5%; } }

.close-box {
  padding-right: 2rem; }

.basic-settings__subheader-info {
  font-size: 1.0625rem;
  color: #616668;
  font-weight: 100;
  margin-bottom: 1rem; }

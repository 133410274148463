@import "global/bootstrap/functions";
@import "global/bootstrap-overrides/variables";
@import "global/bootstrap/mixins";
@import "global/global-mixins/global-mixins";

.save-settings {
    position: sticky;
    z-index: 999;
    height: 100px;
    bottom: 0;
    width: 100%;
    margin-top: $baseline * 6;
}

.save-settings--button {
    height: 100%;
    display: flex;
    align-items: center;
}

.webtraxs-tutorials {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: $baseline * 3;
    grid-gap: $baseline * 4;

    @include media-breakpoint-down(md) {
        grid-template-columns: auto auto;
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: auto;
    }
}

.settings-calltracking {
    padding: $baseline * 3;
}

.card--header {
    font-size: 1.75rem;
    font-weight: 500;
}
.card--content {
    font-size: $font-size-default;
    font-weight: 200;
    margin: $baseline 0;
    b {
        font-weight: 600;
    }
}
.card--button {
    margin: $baseline 0;
}
.card--codeblock {
    background-color: $gray-light;
    font-size: 1rem;
    padding: $baseline * 2;
    margin: $baseline * 2;
    word-wrap: break-word;
    position: relative;
}

.green-checkmark {
    float: left;
    width: 30px;
    height: 30px;
    background: $green;
    border-radius: 30px;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        color: $white;
        padding: 3px;
    }
}

.account-info {
  padding: 1.3rem; }
  .account-info .add-logo {
    background: #1b5170;
    padding: 12px;
    margin-bottom: 14px; }
    .account-info .add-logo p {
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
      padding: 0; }
  .account-info ul,
  .account-info h3,
  .account-info h4 {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 4px; }
  .account-info li {
    overflow: hidden;
    text-overflow: ellipsis; }

.table {
  border-bottom: 1px solid #dfe6ec;
}

.campaign-dash-table {
  th:first-child {
    padding-left: 2rem;
    width: 45%;
  }
  td:first-child {
    padding-left: 2rem;
  }
  th:nth-of-type(2) {
    width: 20%;
  }
  th:nth-of-type(3) {
    width: 10%;
  }
  th:nth-of-type(4) {
    width: 20%;
  }
  th:nth-of-type(5) {
    width: 5%;
  }
}

.campaigns-dash-table__company {
  position: relative;
  span {
    left: -0.5rem;
    position: relative;
    font-size: unset;
  }
}

.icon-arrow-down {
  display: inline-block;
  width: 5px;
  .icon {
    height: 9px;
    margin-bottom: 3px;
  }
}
.icon-arrow-up {
  display: inline-block;
  width: 5px;
  .icon {
    height: 9px;
    margin-bottom: 3px;
  }
}
